import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { login, logout } from "../../../store/backofficeUser/actions";
import ApiClient from "../../../services/ApiClient";
import { useSnackbar } from "notistack";
import Snackbar from "../../Snackbar/Snackbar";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import PageContentContainer from "../../Container/PageContentContainer";
import ContentBox from "../../ContentBox/ContentBox";
import { Box, Stack, Typography } from "@mui/material";
import ButtonLoading from "../../Button/ButtonLoading";
import _ from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useForm from "../../../hooks/useForm";
import ValidatorForm from "../../Validator/ValidatorForm";
import { passwordFieldDefault } from "../../../services/validationRules";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import { formValue } from "../../../services/formServiceFunctions";
import { UseForm } from "../../../types/UseForm";

export default function NewPasswordPage({}) {
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const token = query.get("t");
  const email = query.get("email") || "";
  const variant = query.get("variant");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    await ApiClient.post("set_user_password", {
      body: JSON.stringify({
        password: values.password,
        token: token,
        id: id,
      }),
    }).then(
      (successResponse) => {
        if (
          !successResponse ||
          _.isEmpty(successResponse.email) ||
          _.toLower(successResponse.email) !== _.toLower(email)
        ) {
          loginErrorMessage();
          return;
        }
        login({ email: email, password: values.password }).then(
          () => {
            navigate("/");
          },
          () => {
            loginErrorMessage();
          }
        );
      },
      () => {
        loginErrorMessage();
      }
    );
  };

  const initialValues = {
    password: "",
  };

  const { values, errors, handleChange, handleSubmit, handleBlur, registerValidators }: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  const recoveryNotFoundMessage = () => {
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar message={"Der Einmal-Link aus Ihrer E-Mail ist nicht mehr gültig."} isNonInteractive />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  const loginErrorMessage = () => {
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={
              "Ihre Daten konnten nicht verifiziert werden. Sollte dieses Problem bestehen bleiben nutzen Sie bitte erneut den Link aus Ihrer E-Mail"
            }
            isNonInteractive
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  useEffect(() => {
    if (!id || !token || !email) {
      navigate("/login");
      return;
    }

    logout();

    ApiClient.get("/check_user_password_recovery?t=" + token + "&id=" + id).then(
      (successResponse) => {
        if (!successResponse.passwordRecoveryExists) {
          recoveryNotFoundMessage();
          navigate("/login", { state: { email: email } });
          return;
        }
        setIsChecking(false);
      },
      () => recoveryNotFoundMessage()
    );
  }, []);

  if (isChecking) {
    return <LegalbirdLoader centered={true} />;
  }

  return (
    <>
      <PageContentContainer size={"small"}>
        <ContentBox
          headline={
            variant === "invite"
              ? "Vielen Dank für Ihre Bestätigung und herzlich willkommen!"
              : "Jetzt ein neues Passwort für Legalbird.io festlegen"
          }
        >
          {variant === "invite" && (
            <Typography variant={"h5"} sx={{ textAlign: "center" }}>
              Jetzt persönliches Passwort festlegen
            </Typography>
          )}
          <ValidatorForm onSubmit={handleSubmit}>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              spacing={3}
              sx={{ marginTop: "1rem" }}
            >
              <ValidatorTextField
                name={"password"}
                label={"Ihr neues Passwort"}
                type={showPassword ? "text" : "password"}
                value={formValue(values, "password")}
                onChange={handleChange}
                registerValidators={registerValidators}
                validators={[...passwordFieldDefault]}
                onBlur={handleBlur}
                error={!!errors["password"]}
                helperText={errors["password"]}
                sx={{ width: "300px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                isMandatory={false}
                dependentValidationFields={[]}
              />
              <Box sx={{ paddingTop: "1rem" }}>
                <ButtonLoading
                  sx={{ width: 300 }}
                  fullWidth={false}
                  isLoading={isLoading}
                  type={"submit"}
                  variant={"contained"}
                >
                  Speichern und Anmelden
                </ButtonLoading>
              </Box>
            </Stack>
          </ValidatorForm>
        </ContentBox>
      </PageContentContainer>
    </>
  );
}
