import { useEffect, useState } from "react";
import ApiClient from "../../../../services/ApiClient";
import SubmitButton from "../../../Button/SubmitButton";
import { AbstractCase } from "../../../../types/AbstractCase";
import { useCurrentUser } from "../../../../provider/CurrentUserProvider";
import { userHasOneOfTheseRoles } from "../../../../services/backofficeUserService";
import { CaseDeletionPossibleStatus } from "../../../../types/CaseDeletionPossibleStatus";
import Typography from "@mui/material/Typography";
import { deleteCaseOrAccountModalStyle } from "../deleteCaseOrAccountModalStyle";
import { CircularProgress, Stack } from "@mui/material";

type DeleteCaseModalContentProps = {
  product: AbstractCase;
  refreshPage: Function;
  handleClose: Function;
};

export default function DeleteCaseModalContent({ product, refreshPage, handleClose }: DeleteCaseModalContentProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [caseDeletionPossibleStatus, setCaseDeletionPossibleStatus] = useState<CaseDeletionPossibleStatus | null>(null);
  const currentUser = useCurrentUser();
  const userIsAdminOrAccounting = userHasOneOfTheseRoles(currentUser, ["ROLE_ADMIN", "ROLE_ACCOUNTING"]);

  async function loadCaseDeletionPossibleStatus() {
    const response: CaseDeletionPossibleStatus = await ApiClient.get(
      `/lb/case_deletion/get_case_deletion_possible_status/${product.id}`
    );
    setCaseDeletionPossibleStatus(response);
  }

  useEffect(() => {
    loadCaseDeletionPossibleStatus();
  }, []);

  const deletionWarnings = [];
  let deletionAllowed = true;

  if (caseDeletionPossibleStatus === null) {
    return (
      <Stack direction={"row"} paddingY={2} justifyContent={"center"}>
        <CircularProgress />
      </Stack>
    );
  }

  if (caseDeletionPossibleStatus.hasAcquisitionPartnerAdvocard) {
    if (userIsAdminOrAccounting) {
      deletionWarnings.push(
        "Achtung: Dieser Fall wurde von der Advocard übermittelt. Es kann also noch eine Zahlung eingehen. Diese wird dann nicht zugeordnet werden können!"
      );
    } else {
      deletionWarnings.push(
        "Achtung: Dieser Fall wurde von der Advocard übermittelt. Es kann also noch zu Buchungseinträgen kommen und der Fall kann daher nicht gelöscht werden."
      );
      deletionAllowed = false;
    }
  }

  if (caseDeletionPossibleStatus.hasBookingEntry) {
    deletionWarnings.push("Achtung: Dieser Fall hat Buchungseinträge!");
    if (!userIsAdminOrAccounting) {
      deletionAllowed = false;
    }
  }

  if (caseDeletionPossibleStatus.isAdvancedCase) {
    deletionWarnings.push(
      "Achtung: Dieser Fall ist schon zu weit fortgeschritten und unterliegt der Aufbewahrungspflicht!"
    );
  }

  async function deleteCase() {
    if (!product.backofficeCase) {
      return;
    }
    setIsLoading(true);
    await ApiClient.delete("backoffice_cases/" + product.backofficeCase.id);
    setIsLoading(false);
    handleClose();
    refreshPage();
  }

  return (
    <>
      {deletionWarnings.map((warning) => (
        <Typography sx={deleteCaseOrAccountModalStyle.warning}>{warning}</Typography>
      ))}
      {deletionAllowed && (
        <SubmitButton isLoading={isLoading} onClick={deleteCase} variant={"contained"} sx={{ marginTop: 2 }}>
          {deletionWarnings.length > 0 ? "Trotzdem löschen" : "Löschen"}
        </SubmitButton>
      )}
    </>
  );
}
