import React, { useState } from "react";
import * as PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CircleIcon from "../CircleIcon/CircleIcon";
import iconPdf from "../../assets/icon/picture_as_pdf-24px.svg";
import iconAttachment from "../../assets/icon/attach_file-24px.svg";
import iconImage from "../../assets/icon/collections-24px.svg";
import PopUpMenu from "../PopUpMenu/PopUpMenu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import moment from "moment";
import { getMediaObjectSize } from "../../services/formServiceFunctions";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import { getCreatedBy } from "../../services/backofficeUserService";
import ChangeMediaObjectDescriptionModal from "../ElectronicFileFolder/ChangeMediaObjectDescriptionModal";
import ChangeMediaObjectFolderModal from "../ElectronicFileFolder/ChangeMediaObjectFolderModal";
import ChangeMediaObjectVisibilityModal from "../ElectronicFileFolder/ChangeMediaObjectVisibilityModal";
import MediaObjectChips from "./MediaObjectChips";
import DisplayOrDownloadMediaObjectWrapper from "../ElectronicFileFolder/DisplayOrDownloadMediaObjectWrapper";
import { Box, Hidden } from "@mui/material";
import { useBackofficeUser } from "../../provider/BackofficeUserProvider";
import { Star } from "@mui/icons-material";
import mediaObjectStyle from "./mediaObjectStyle";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import ChangeMediaObjectIdentifierModal from "../ElectronicFileFolder/ChangeMediaObjectIdentifierModal";

const MediaObject = ({
  mediaObject,
  updateMediaObjects,
  isNonInteractive,
  handleOpenMediaObject,
  folderStructureView,
}) => {
  const currentUser = useCurrentUser();
  const { backofficeUsers } = useBackofficeUser();
  const [deleteMediaObjectOpen, setDeleteMediaObjectOpen] = useState(false);
  const [changeDescriptionOpen, setChangeDescriptionOpen] = useState(false);
  const [changeVisibilityOpen, setChangeVisibilityOpen] = useState(false);
  const [selectSubfolderOpen, setSelectSubfolderOpen] = useState(false);
  const [changeIdentifierOpen, setChangeIdentifierOpen] = useState(false);
  const updateMutation = useMutation(updateResource);

  const handleDeleteMediaObjectClick = () => {
    updateMutation
      .mutateAsync({
        uri: "media_objects",
        id: mediaObject.id,
        data: {
          deleted: true,
          showInGallery: false,
          showInExternalView: false,
        },
      })
      .then(() => updateMediaObjects());
    setDeleteMediaObjectOpen(false);
  };

  const handleMarkAsFavourite = (isFavourite) => {
    updateMutation
      .mutateAsync({
        uri: "media_objects",
        id: mediaObject.id,
        data: {
          isFavourite: isFavourite,
        },
      })
      .then(() => updateMediaObjects());
  };

  const getIconData = (mediaObject) => {
    if (mediaObject.isFavourite) {
      return { icon: <Star sx={{ color: "primary.main" }} />, type: "muiIcon" };
    }
    let icon = iconAttachment;

    switch (mediaObject.mimeType) {
      case "image/jpg":
      case "image/jpeg":
      case "image/png":
        icon = iconImage;
        break;
      case "application/pdf":
        icon = iconPdf;
        break;
      default:
        break;
    }
    return { icon: icon, type: "image" };
  };

  //need some sort of info if the user is an admin
  const isExternal = currentUser.isExternal;
  const isExternalEditAllowed = !isExternal || mediaObject.createdBy === currentUser["@id"];
  const deleteAllowed = currentUser;
  const createdAt = moment(mediaObject.created);

  const { icon, type } = getIconData(mediaObject);

  return (
    <Grid container wrap={"nowrap"}>
      <Hidden only={"xs"}>
        <Grid item sx={mediaObjectStyle.icon}>
          <CircleIcon icon={icon} type={type} />
        </Grid>
      </Hidden>
      <Grid item sx={mediaObjectStyle.item}>
        <Grid container>
          <Grid item xs={12}>
            {!isNonInteractive ? (
              <DisplayOrDownloadMediaObjectWrapper
                mediaObject={mediaObject}
                setMediaObjectToDisplay={handleOpenMediaObject}
              >
                <Box sx={mediaObjectStyle.mediaObjectName}>{mediaObject.originalName}</Box>
              </DisplayOrDownloadMediaObjectWrapper>
            ) : (
              <Box sx={mediaObjectStyle.headline}>{mediaObject.originalName}</Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              ...mediaObjectStyle.description,
              ...(mediaObject.isFavourite ? mediaObjectStyle.bold : {}),
            }}
          >
            {mediaObject.description}
          </Grid>
          <Grid item>
            Hinzugefügt durch {getCreatedBy(mediaObject.createdBy, backofficeUsers)} am {createdAt.format("DD.MM.YYYY")}{" "}
            um {createdAt.format("HH:mm")} Uhr | {getMediaObjectSize(mediaObject.size)}
          </Grid>
          {!isExternal && (
            <Grid item xs={12}>
              <MediaObjectChips mediaObject={mediaObject} showFolder={!folderStructureView} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isNonInteractive && isExternalEditAllowed && (
        <Grid item>
          <PopUpMenu>
            <MenuItem onClick={() => setChangeDescriptionOpen(true)}>Beschreibung ändern</MenuItem>
            {!isExternal && <MenuItem onClick={() => setChangeVisibilityOpen(true)}>Sichtbarkeit bearbeiten</MenuItem>}
            {!isExternal && (
              <MenuItem onClick={() => setSelectSubfolderOpen(true)}>{"In anderen Ordner schieben"}</MenuItem>
            )}
            {!isExternal && <MenuItem onClick={() => setChangeIdentifierOpen(true)}>Art des Dokuments wählen</MenuItem>}
            <MenuItem component="a" download={mediaObject.originalName} href={mediaObject.downloadUrl}>
              Herunterladen
            </MenuItem>
            {!isExternal && (
              <MenuItem onClick={() => handleMarkAsFavourite(!mediaObject.isFavourite)}>
                {mediaObject.isFavourite ? "Als Favorit entfernen" : "Als Favorit markieren"}
              </MenuItem>
            )}
            {deleteAllowed && <MenuItem onClick={() => setDeleteMediaObjectOpen(true)}>Löschen</MenuItem>}
          </PopUpMenu>
          {deleteAllowed && (
            <LegalbirdIoModal
              handleClose={() => setDeleteMediaObjectOpen(false)}
              open={deleteMediaObjectOpen}
              title={"Datei löschen?"}
              submitButton={
                <Button variant={"contained"} onClick={handleDeleteMediaObjectClick}>
                  Ja
                </Button>
              }
            >
              <p className={"center"}>
                Diese Datei wird unwiderruflich gelöscht. Sind Sie sicher, dass Sie die Datei löschen möchten?
              </p>
            </LegalbirdIoModal>
          )}
          <ChangeMediaObjectDescriptionModal
            setChangeDescriptionOpen={setChangeDescriptionOpen}
            changeDescriptionOpen={changeDescriptionOpen}
            mediaObject={mediaObject}
            updateMediaObjects={updateMediaObjects}
          />
          <ChangeMediaObjectVisibilityModal
            mediaObject={mediaObject}
            updateMediaObjects={updateMediaObjects}
            changeVisibilityOpen={changeVisibilityOpen}
            setChangeVisibilityOpen={setChangeVisibilityOpen}
          />
          <ChangeMediaObjectFolderModal
            setSelectSubfolderOpen={setSelectSubfolderOpen}
            updateMediaObjects={updateMediaObjects}
            mediaObject={mediaObject}
            selectSubfolderOpen={selectSubfolderOpen}
          />
          <ChangeMediaObjectIdentifierModal
            setChangeIdentifierOpen={setChangeIdentifierOpen}
            changeIdentifierOpen={changeIdentifierOpen}
            mediaObject={mediaObject}
            updateMediaObjects={updateMediaObjects}
          />
        </Grid>
      )}
      {isExternal && !isExternalEditAllowed && (
        <Grid item>
          <PopUpMenu>
            <MenuItem component={"a"} download={true} href={mediaObject.downloadUrl}>
              Herunterladen
            </MenuItem>
          </PopUpMenu>
        </Grid>
      )}
    </Grid>
  );
};

MediaObject.propTypes = {
  mediaObject: PropTypes.object.isRequired,
  updateMediaObjects: PropTypes.func,
  isNonInteractive: PropTypes.bool,
  folderStructureView: PropTypes.bool,
  handleOpenMediaObject: PropTypes.func,
};

MediaObject.defaultProps = {
  isNonInteractive: false,
  folderStructureView: false,
};

export default MediaObject;
