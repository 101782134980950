import { useEffect, useState } from "react";
import ApiClient from "../../../../services/ApiClient";
import SubmitButton from "../../../Button/SubmitButton";
import { AbstractCase } from "../../../../types/AbstractCase";
import { AccountDeletionPossibleStatus } from "../../../../types/AccountDeletionPossibleStatus";
import { useCurrentUser } from "../../../../provider/CurrentUserProvider";
import { userHasOneOfTheseRoles } from "../../../../services/backofficeUserService";
import { deleteCaseOrAccountModalStyle } from "../deleteCaseOrAccountModalStyle";
import Typography from "@mui/material/Typography";
import { CircularProgress, Stack } from "@mui/material";

type DeleteAccountModalProps = {
  product: AbstractCase;
  refreshPage: Function;
  handleClose: Function;
};

export default function DeleteAccountModalContent({ product, refreshPage, handleClose }: DeleteAccountModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [accountDeletionPossibleStatus, setAccountDeletionPossibleStatus] =
    useState<AccountDeletionPossibleStatus | null>(null);

  const currentUser = useCurrentUser();
  const userIsAdminOrAccounting = userHasOneOfTheseRoles(currentUser, ["ROLE_ADMIN", "ROLE_ACCOUNTING"]);

  async function loadAccountDeletionPossibleStatus() {
    if (!product.customer?.id) {
      return null;
    }
    const response: AccountDeletionPossibleStatus = await ApiClient.get(
      `/lb/account_deletion/get_account_deletion_possible_status/${product.customer.id}`
    );
    setAccountDeletionPossibleStatus(response);
  }

  useEffect(() => {
    loadAccountDeletionPossibleStatus();
  }, []);

  if (accountDeletionPossibleStatus === null) {
    return (
      <Stack direction={"row"} paddingY={2} justifyContent={"center"}>
        <CircularProgress />
      </Stack>
    );
  }

  async function confirmDeletion() {
    if (!product.customer?.id) {
      return null;
    }
    setIsLoading(true);
    await ApiClient.get(`/lb/services/account_deletion/force/${product.customer.id}`);
    setIsLoading(false);
    handleClose();
    refreshPage();
  }

  const deletionWarnings = [];
  let deletionAllowed = true;

  if (accountDeletionPossibleStatus.hasAcquisitionPartnerAdvocardCase) {
    if (userIsAdminOrAccounting) {
      deletionWarnings.push(
        "Sind Sie sicher, dass Sie alle Daten und Fälle löschen möchten? Es gibt mindestens einen Fall von der Advocard, bei dem noch eine Zahlung eingehen kann. Diese wird dann nicht zugeordnet werden können."
      );
    } else {
      deletionWarnings.push(
        "Achtung: Es gibt mindestens einen Fall von der Advocard, bei dem noch eine Zahlung eingehen kann. Diese Zahlung könnte bei einer Löschung nicht mehr zugeordnet werden. Daher kann das Konto nicht gelöscht werden. Bitte bestätige dem Kunden die Löschung manuell per E-Mail und verfälsche die E-Mail dann mit “xxx_”."
      );
      deletionAllowed = false;
    }
  }

  if (accountDeletionPossibleStatus.hasBookingEntry) {
    deletionWarnings.push(
      "Es gibt mindestens einen Fall mit einem Buchungseintrag. Es kann somit nicht alles gelöscht werden, sondern ggf. nur einzelne Fälle oder der Kundenzugang muss verfälscht werden (mit xxx_ vor der E-Mail)."
    );
    if (!userIsAdminOrAccounting) {
      deletionAllowed = false;
    }
  }

  if (accountDeletionPossibleStatus.hasAdvancedCase) {
    deletionWarnings.push(
      "Sind Sie sicher, dass Sie alle Daten und Fälle löschen möchten? Es gibt mindestens einen Fall, der so weit fortgeschritten ist, dass uns entweder eine Vollmacht vorliegt und/oder wir sogar bereits rechtlich tätig geworden sind. Beim Löschen könnten somit Aufbewahrungspflichten verletzt werden!"
    );
  }

  return (
    <>
      {deletionWarnings.map((warning) => (
        <Typography sx={deleteCaseOrAccountModalStyle.warning}>{warning}</Typography>
      ))}
      {deletionAllowed && (
        <SubmitButton isLoading={isLoading} onClick={confirmDeletion} variant={"contained"} sx={{ marginTop: 2 }}>
          {deletionWarnings.length > 0 ? "Trotzdem löschen" : "Löschen"}
        </SubmitButton>
      )}
    </>
  );
}
