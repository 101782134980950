import React, { useEffect, useState } from "react";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import { MediaObject } from "../../types/MediaObject";
import _ from "lodash";
import { getIdentifiersAutocompleteOptions } from "../../services/identifierService";
import LegalbirdAutoComplete from "../AutoComplete/LegalbirdAutoComplete";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import LegalbirdAutocompleteOption from "../../types/LegalbirdAutocompleteOption";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";

type ChangeMediaObjectFolderModalProps = {
  setChangeIdentifierOpen: (value: boolean) => void;
  changeIdentifierOpen: boolean;
  mediaObject: MediaObject;
  updateMediaObjects: () => void;
};

export default function ChangeMediaObjectIdentifierModal({
  setChangeIdentifierOpen,
  changeIdentifierOpen,
  mediaObject,
  updateMediaObjects,
}: ChangeMediaObjectFolderModalProps) {
  const [identifier, setIdentifier] = useState("");
  const [identifierOptions, setIdentifierOptions] = useState<LegalbirdAutocompleteOption[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const currentUser = useCurrentUser();

  useEffect(() => {
    if (identifierOptions.length) {
      return;
    }
    getIdentifiersAutocompleteOptions(mediaObject.product).then((options) => {
      setIdentifierOptions(options);
    });
  }, [changeIdentifierOpen]);

  const autoCompleteValue = _.find(identifierOptions, (identifierOption) => identifierOption.value === identifier);

  const handleSaveNewIdentifier = async () => {
    setIsSaving(true);
    try {
      await ApiClient.put(`lb/services/media_object_change_identifier/${mediaObject.id}`, {
        body: JSON.stringify({
          identifier,
          backofficeUserId: currentUser.id,
        }),
      });
      updateMediaObjects();
      enqueueSnackbar("", {
        content: () => (
          <Box>
            <Snackbar message={"Art des Dokuments wurde erfolgreich geändert"} />
          </Box>
        ),
      });
    } catch (e) {
      enqueueSnackbar("", {
        content: () => (
          <Box>
            <Snackbar message={"Art des Dokuments konnte nicht geändert werden"} />
          </Box>
        ),
      });
    } finally {
      setIsSaving(false);
      setChangeIdentifierOpen(false);
    }
  };

  return (
    <LegalbirdIoModal
      handleClose={() => setChangeIdentifierOpen(false)}
      open={changeIdentifierOpen}
      title={"Art des Dokuments wählen"}
      submitButton={
        <ButtonLoading
          variant={"contained"}
          onClick={handleSaveNewIdentifier}
          isLoading={isSaving}
          disabled={!identifier}
        >
          Auswahl speichern
        </ButtonLoading>
      }
    >
      <Typography paddingBottom={2}>
        Bitte wählen Sie im untenstehenden Dropdown aus, als welche Art von Dokument es gespeichert werden soll.{" "}
        <strong>Achtung:</strong> Die Änderung wird alle Aktionen des Dokumententyps, wie beispielsweise Aktivitäten und
        Nachrichtenversand an den Mandanten, ausführen!
      </Typography>
      <LegalbirdAutoComplete
        setValue={(event: SelectChangeEvent, option: LegalbirdAutocompleteOption) => {
          setIdentifier(option.value!);
        }}
        value={autoCompleteValue}
        options={identifierOptions}
        label={"Art des Dokuments"}
      />
    </LegalbirdIoModal>
  );
}
