import _ from "lodash";
import ApiClient from "./ApiClient";
import { translate } from "./Translations/translatorService";

export const getIdentifiers = async (productClassName) => {
  const identifiers = await ApiClient.get("lb/services/document_labels");
  return _.merge(identifiers[productClassName], identifiers["common"]);
};

export const getIdentifiersAutocompleteOptions = async (productClassName, includeNoLabelOption) => {
  const identifiers = await getIdentifiers(productClassName);

  let identifierOptions = _.map(identifiers, (identifier, key) => {
    return {
      label: key + " - " + translate(`identifier.${productClassName}.${key}.label`),
      value: key,
    };
  });

  identifierOptions = _.sortBy(identifierOptions, (option) => option.label);

  if (includeNoLabelOption) {
    return [{ label: "Anderes Dokument", value: "no_label" }, ...identifierOptions];
  }

  return identifierOptions;
};
