import { displayOrDownloadMediaObjectCellStyle } from "./displayOrDownloadMediaObjectCellStyle";
import _ from "lodash";
import { mimeTypes } from "../../services/Files/fileService";
import { isMobile } from "react-device-detect";
import Typography from "@mui/material/Typography";
import { MediaObject } from "../../types/MediaObject";

type DisplayOrDownloadMediaObjectWrapperProps = {
  mediaObject: MediaObject;
  children: React.ReactNode;
  setMediaObjectToDisplay: Function;
};

export default function DisplayOrDownloadMediaObjectWrapper({
  children,
  mediaObject,
  setMediaObjectToDisplay,
}: DisplayOrDownloadMediaObjectWrapperProps) {
  if (!isMobile && _.includes([...mimeTypes.pdf, ...mimeTypes.image], mediaObject.mimeType)) {
    return <div onClick={() => setMediaObjectToDisplay(mediaObject)}>{children}</div>;
  }
  //using displayUrlhere lets the browser decide which files he wants to display or download
  return (
    <Typography sx={displayOrDownloadMediaObjectCellStyle.link}>
      <a href={mediaObject.displayUrl} target={"_blank"} download>
        {children}
      </a>
    </Typography>
  );
}
