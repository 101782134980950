import React, { useState } from "react";
import { emailsStyle } from "./emailsStyle";
import _ from "lodash";
import Email from "./Email";
import ButtonLoading from "../Button/ButtonLoading";
import ContentBox from "../ContentBox/ContentBox";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { Box } from "@mui/material";
import { AbstractCase } from "../../types/AbstractCase";
import { EmailMessage } from "../../types/EmailMessage";

type EmailsProps = {
  product: AbstractCase;
};

const Emails = ({ product }: EmailsProps) => {
  const [emailDisplayCount, setEmailDisplayCount] = useState(3);
  const currentUser = useCurrentUser();

  let emailFilters: Record<string, any> = {
    backofficeCase: product.backofficeCase!["@id"],
  };

  if (currentUser.isExternal) {
    emailFilters.hiddenExternally = false;
  }

  const { data: emails, isLoading } = useQuery(
    queryKeys.collection("email_messages", emailFilters),
    () => fetchCollection("email_messages", emailFilters),
    {
      enabled: !!product,
    }
  );

  if (!emails || isLoading) {
    return null;
  }

  const displayedEmails: EmailMessage[] = _.slice(emails["hydra:member"], 0, emailDisplayCount);
  return (
    <>
      <Box sx={emailsStyle.scrollToAnchor} id={"mails"} />
      <ContentBox headline="E-Mails">
        {displayedEmails.length > 0 ? (
          _.map(displayedEmails, (emailMessage, index) => (
            <Box margin={"2rem 0 0 0"} key={index}>
              <Email emailMessage={emailMessage} updateEmailListEntry={() => {}} product={product} />
            </Box>
          ))
        ) : (
          <Box sx={emailsStyle.noMails}>Es sind noch keine Mails vorhanden</Box>
        )}
        {emails["hydra:totalItems"] > emailDisplayCount && (
          <ButtonLoading onClick={() => setEmailDisplayCount(emailDisplayCount + 10)} sx={emailsStyle.button}>
            Mehr anzeigen
          </ButtonLoading>
        )}
        {displayedEmails.length > 3 && (
          <ButtonLoading onClick={() => setEmailDisplayCount(3)} sx={emailsStyle.button}>
            Weniger anzeigen
          </ButtonLoading>
        )}
      </ContentBox>
    </>
  );
};

export default Emails;
