import { formValue } from "../../../formServiceFunctions";
import * as stagesDocument from "../../../Stages/StagesDocument";
import { trueFalseDefaultOptions } from "../formDefinitionFunctions";
import { translate } from "../../../Translations/translatorService";
import { dateFieldDefault, insurancePickerValidator, textFieldDefault } from "../../../validationRules";
import React from "react";
import addressFields from "../modules/addressFields";
import personFields from "../modules/personFields";

const childrenFields = () => {
  let fields = [];

  for (let i = 0; i < 6; i++) {
    fields.push(
      {
        type: "ValidatorTextField",
        path: "alimony.children[" + i + "].name",
        label: translate("alimony.children.name.label"),
        validators: textFieldDefault,
        isHidden: ({ product }) => product.numberOfChildren <= i,
        isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
      },
      {
        type: "ValidatorDateField",
        path: "alimony.children[" + i + "].birthDate",
        label: translate("alimony.children.birthDate.label"),
        isHidden: ({ product }) => product.numberOfChildren <= i,
        isDisabled: () => true,
      },
      {
        type: "EmptyElement",
        //because path is also used as the key for react
        path: "empty" + i,
      },
      {
        type: "ValidatorSelect",
        path: "alimony.children[" + i + "].childOf",
        label: translate("alimony.children.childOf.label"),
        options: () => [
          { value: "petitioner", label: translate("alimony.children.childOf.values.petitioner") },
          { value: "partner", label: translate("alimony.children.childOf.values.partner") },
          { value: "both", label: translate("alimony.children.childOf.values.both") },
        ],
        isHidden: ({ product }) => product.numberOfChildren <= i,
        isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
      },
      {
        type: "ValidatorSelect",
        path: "alimony.children[" + i + "].livesWith",
        label: translate("alimony.children.livesWith.label"),
        options: () => [
          { value: "petitioner", label: translate("alimony.children.livesWith.values.petitioner") },
          { value: "partner", label: translate("alimony.children.livesWith.values.partner") },
          { value: "both", label: translate("alimony.children.livesWith.values.both") },
          { value: "none", label: translate("alimony.children.livesWith.values.none") },
        ],
        isHidden: ({ product }) => product.numberOfChildren <= i,
        isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
      },
      {
        type: "ValidatorSelect",
        path: "alimony.children[" + i + "].childAllowanceReceiver",
        label: translate("alimony.children.childAllowanceReceiver.label"),
        options: () => [
          { value: "petitioner", label: translate("alimony.children.childAllowanceReceiver.values.petitioner") },
          { value: "partner", label: translate("alimony.children.childAllowanceReceiver.values.partner") },
          { value: "both", label: translate("alimony.children.childAllowanceReceiver.values.both") },
          { value: "none", label: translate("alimony.children.childAllowanceReceiver.values.none") },
        ],
        isHidden: ({ product }) => product.numberOfChildren <= i,
        isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
      }
    );
  }
  return fields;
};

export const fullFormDefinition = (product) => ({
  product: {
    sections: [
      {
        label: "Typ der Analyse",
        elements: [
          {
            type: "ValidatorSelect",
            path: `alimony.basicChoice`,
            isDisabled: () => true,
            options: () => [{ value: "__null__" }, { value: "complete" }, { value: "children" }, { value: "other" }],
          },
        ],
      },
      {
        label: "Finanzielle Situation Antragsteller",
        isHidden: ({ product }) => product.basicChoice === "other",
        elements: [
          {
            type: "ValidatorSelect",
            path: "alimony.alimonyPetitioner.isEmployed",
            options: () => trueFalseDefaultOptions("alimony.alimonyPetitioner.isEmployed"),
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "alimony.alimonyPetitioner.hasMarriageRelatedDisadvantages",
            options: () => trueFalseDefaultOptions("alimony.alimonyPetitioner.hasMarriageRelatedDisadvantages"),
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
          },
          {
            type: "ValidatorNumberField",
            path: "alimony.alimonyPetitioner.netIncome",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            fieldType: "money",
          },
          {
            type: "ValidatorNumberField",
            path: "alimony.alimonyPetitioner.otherIncome",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            fieldType: "money",
          },
          {
            type: "ValidatorNumberField",
            path: "alimony.alimonyPetitioner.livingExpenses",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            numberType: "float",
            fieldType: "money",
          },
          {
            type: "ValidatorTextField",
            path: "alimony.alimonyPetitioner.otherExpenses",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            fieldType: "money",
            numberType: "float",
          },
        ],
      },
      {
        label: "Finanzielle Situation Ex-Partner",
        isHidden: ({ product }) => product.basicChoice === "other",
        elements: [
          {
            type: "ValidatorSelect",
            path: "alimony.alimonyPartner.person.gender",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "alimony.alimonyPartner.person.givenName",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "alimony.alimonyPartner.person.familyName",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "alimony.alimonyPartner.isEmployed",
            options: () => trueFalseDefaultOptions("alimony.alimonyPartner.isEmployed"),
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "alimony.alimonyPartner.hasMarriageRelatedDisadvantages",
            options: () => trueFalseDefaultOptions("alimony.alimonyPartner.hasMarriageRelatedDisadvantages"),
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
          },
          {
            type: "ValidatorNumberField",
            path: "alimony.alimonyPartner.netIncome",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            fieldType: "money",
          },
          {
            type: "ValidatorNumberField",
            path: "alimony.alimonyPartner.otherIncome",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            fieldType: "money",
          },
          {
            type: "ValidatorNumberField",
            path: "alimony.alimonyPartner.livingExpenses",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            numberType: "float",
            fieldType: "money",
          },
          {
            type: "ValidatorNumberField",
            path: "alimony.alimonyPartner.otherExpenses",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            fieldType: "money",
            numberType: "float",
          },
        ],
      },
      {
        label: "Familiäre Situation",
        isHidden: ({ product }) => product.basicChoice === "other",
        elements: [
          {
            type: "ValidatorDateField",
            path: "alimony.dateMarriage",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "alimony.areSeparated",
            options: () => trueFalseDefaultOptions("alimony.areSeparated"),
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
          },
          {
            type: "ValidatorDateField",
            path: "alimony.dateSeparation",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY || product.areSeparated !== true,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "alimony.areDivorced",
            options: () => trueFalseDefaultOptions("alimony.areDivorced"),
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
          },
          {
            type: "ValidatorDateField",
            path: "alimony.dateDivorce",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY || product.areDivorced !== true,
            validators: dateFieldDefault,
          },
        ],
      },
      {
        label: "Angaben zu Kindern",
        isHidden: ({ values, product }) =>
          product.basicChoice === "other" || parseInt(formValue(values, "alimony.numberOfChildren"), 10) === 0,
        elements: childrenFields(),
      },
      {
        label: "Persönliche Angaben",
        elements: [
          {
            type: "ValidatorSelect",
            path: "alimony.client.gender",
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "alimony.client.givenName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "alimony.client.familyName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "alimony.client.residenceAddress.streetAddress",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "alimony.client.residenceAddress.postalCode",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "alimony.client.residenceAddress.addressLocality",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
            validators: [...textFieldDefault],
          },
        ],
      },
      {
        label: "Persönliche Angaben Antragsteller",
        elements: [
          ...personFields({
            path: "alimony.alimonyPetitioner.person",
          }),
          ...addressFields({
            path: "alimony.alimonyPetitioner.person.residenceAddress",
            isDisabled: ({ product }) => product.stage > stagesDocument.STAGE_READY,
          }),
        ],
      },
      {
        label: "Anliegen",
        elements: [
          {
            type: "ValidatorTextField",
            path: "alimony.personalTextMessage",
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_READY,
            additionalProps: {
              multiline: true,
              rows: 12,
              fullWidth: true,
            },
          },
        ],
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "alimony.reference",
            isDisabled: () => true,
          },
        ],
      },
      {
        label: "Angaben zur Versicherung",
        elements: [
          {
            type: "InsurancePicker",
            path: "alimony.insurance.insurance",
            validators: insurancePickerValidator(product),
          },
          {
            type: "ValidatorTextField",
            path: "alimony.insurance.insurancePolicyNumber",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorTextField",
            path: "alimony.insurance.caseReferenceNumber",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorNumberField",
            path: "alimony.insurance.deductible",
            isDisabled: ({ product }) => product.stage >= stagesDocument.STAGE_CHECKED,
            fieldType: "money",
            numberType: "float",
          },
        ],
      },
    ],
  },
});
