import { useEffect, useState } from "react";
import PageContentContainer from "../../Container/PageContentContainer";
import PageHeadline, { ProductHeadline } from "../../PageHeadline/PageHeadline";
import { useParams } from "react-router-dom";
import ApiClient, { ResponseError } from "../../../services/ApiClient";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { Typography } from "@mui/material";
import { ExternalReferenceFile as ExternalReferenceFileType } from "../../../types/ExternalReferenceFile";
import ExternalReferenceFile from "../../ExternalReferenceFile/ExternalReferenceFile";

const ExternalReferenceFilePage = () => {
  const { accessToken } = useParams();
  const [referenceFileData, setReferenceFileData] = useState<ExternalReferenceFileType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [accessExpired, setAccessExpired] = useState(false);

  async function loadReferenceFileData() {
    setIsLoading(true);
    try {
      const result = await ApiClient.get("representatives/reference_file/" + accessToken);
      setReferenceFileData(result);
    } catch (e) {
      if (e instanceof ResponseError && e.status === 403) {
        setAccessExpired(true);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    loadReferenceFileData();
  }, []);

  if (isLoading || !referenceFileData) {
    return <LegalbirdLoader centered />;
  }

  if (accessExpired) {
    return (
      <>
        <PageContentContainer>
          <PageHeadline main={"Legalbird Handakte"} />
          <Typography variant={"h2"} sx={{ textAlign: "center", marginTop: "5rem" }}>
            Der Zugang zu dieser Handakte ist abgelaufen. Sollten Sie erneut Zugang benötigen, so kontaktieren Sie bitte
            Ihren Ansprechpartner bei Legalbird.
          </Typography>
        </PageContentContainer>
      </>
    );
  }

  if (isLoading || !referenceFileData) {
    return <LegalbirdLoader centered />;
  }

  const productProxy = {
    productClassName: referenceFileData.productData.productClassName,
    productCategory: referenceFileData.productData.productCategory,
    petitioner: referenceFileData.productData.client,
  };

  return (
    <>
      <PageContentContainer size={"small"}>
        <ProductHeadline product={productProxy} title={"Legalbird Handakte zu"} />
        <ExternalReferenceFile referenceFileData={referenceFileData} />
      </PageContentContainer>
    </>
  );
};

export default ExternalReferenceFilePage;
