import UploadFile from "../CaseUploadFile/UploadFile";
import CreateActivity from "../../Activities/EditActivity/CreateActivity";
import EditNote from "../CaseCreateNote/EditNote";
import React from "react";
import CreatePaidTaskModal from "../CaseModals/CreatePaidTaskModal";
import PauseModal from "../CaseModals/PauseModal";
import StatusModal from "../CaseModals/StatusModal";
import DeleteCaseOrAccountModal from "../CaseModals/DeleteCaseOrAccountModal";
import DocumentResetModal from "../CaseModals/DocumentResetModal";
import ContractResetModal from "../CaseModals/ContractResetModal";
import StageModal from "../CaseModals/StageModal";
import PowerOfAttorneyModal from "../CaseModals/PowerOfAttorneyModal";
import ResendEmailModal from "../CaseModals/ResendEmailModal";
import PaymentTypeSwitchModal from "../CaseModals/PaymentTypeSwitchModal";
import CourtAppointmentModal from "../CaseModals/CourtAppointmentModal";
import CreateActivityNoteModal from "../CaseModals/CreateActivityNoteModal";
import CommunicationMedium from "../CaseBeaAndFax/CommunicationMedium";
import AddInquiryToCustomerModal from "../CaseModals/AddInquiryToCustomerModal";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import ComposeEmailOrMessage from "../CaseWriteEmail/ComposeEmailOrMessage";
import RequestSchufaDataCopyModal from "../CaseModals/RequestSchufaDataCopyModal";

const CaseRelatedModals = ({ open, setOpen, refreshActivities, refreshPage, product }) => {
  const currentUser = useCurrentUser();
  const getDefaultAssignedUser = () => {
    if (currentUser.isLawyer || currentUser.isAccountManager) {
      return currentUser["@id"];
    }
    return product.backofficeCase.accountManager ? product.backofficeCase.accountManager["@id"] : "";
  };

  const handleClose = () => {
    setOpen("");
  };
  return (
    <>
      <UploadFile open={open === "upload"} product={product} closeDialog={() => setOpen("")} />
      <EditNote closeDialog={() => setOpen("")} product={product} open={open === "note"} />
      <ComposeEmailOrMessage open={open === "composeEmailOrMessage"} closeDialog={() => setOpen("")} />
      {!currentUser.isExternal && (
        <>
          <CreateActivity
            open={open === "activity"}
            closeDialog={handleClose}
            updateActivities={() => refreshActivities()}
            product={product}
            caseId={product.backofficeCase["@id"]}
            defaultAssignedUser={getDefaultAssignedUser()}
          />
          <CreateActivityNoteModal
            open={open === "callNotes"}
            closeDialog={handleClose}
            updateActivities={() => refreshActivities()}
            product={product}
            caseId={product.backofficeCase["@id"]}
            defaultAssignedUser={getDefaultAssignedUser()}
          />
          <CommunicationMedium product={product} open={open === "bea_fax"} handleClose={() => setOpen("")} />
          <CreatePaidTaskModal
            product={product}
            open={open === "paidTask"}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <PauseModal product={product} open={open === "pause"} handleClose={handleClose} refreshPage={refreshPage} />
          <StatusModal product={product} open={open === "status"} handleClose={handleClose} refreshPage={refreshPage} />
          <DeleteCaseOrAccountModal
            product={product}
            open={open === "delete"}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <DocumentResetModal
            product={product}
            open={open === "resetDocument"}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <ContractResetModal
            product={product}
            open={open === "resetContract"}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <StageModal product={product} open={open === "stage"} handleClose={handleClose} refreshPage={refreshPage} />
          <PowerOfAttorneyModal
            product={product}
            open={open === "powerOfAttorney"}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <ResendEmailModal product={product} open={open === "resendEmail"} handleClose={() => setOpen("")} />
          <PaymentTypeSwitchModal
            product={product}
            open={open === "privatePayer"}
            handleClose={handleClose}
            refreshPage={refreshPage}
          />
          <CourtAppointmentModal
            product={product}
            handleClose={handleClose}
            open={open === "courtAppointment"}
            refreshPage={refreshPage}
          />
          <RequestSchufaDataCopyModal
            product={product}
            handleClose={handleClose}
            open={open === "requestSchufaDataCopy"}
          />
          <AddInquiryToCustomerModal product={product} open={open === "addRequest"} handleClose={handleClose} />
        </>
      )}
    </>
  );
};
export default CaseRelatedModals;
