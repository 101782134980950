import { useState } from "react";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import { AbstractCase } from "../../../types/AbstractCase";
import DeleteCaseModalContent from "./DeletionModals/DeleteCaseModalContent";
import DeleteAccountModalContent from "./DeletionModals/DeleteAccountModalContent";

type DeleteCaseOrAccountModalProps = {
  product: AbstractCase;
  refreshPage: Function;
  open: boolean;
  handleClose: Function;
};

export default function DeleteCaseOrAccountModalContent({
  product,
  open,
  handleClose,
  refreshPage,
}: DeleteCaseOrAccountModalProps) {
  const [deleteType, setDeleteType] = useState<"case" | "account" | string>("");

  return (
    <LegalbirdIoModal handleClose={handleClose} open={open} title={"Daten löschen"} submitButton={null}>
      <p>Möchten Sie nur den Fall löschen, oder alle Daten und Fälle zu dem Nutzer?</p>
      <Grid container>
        <Grid item xs={12}>
          <ValidatorSelect
            label="Löschen von"
            name="deleteType"
            value={deleteType}
            onChange={(e: SelectChangeEvent) => {
              setDeleteType(e.target.value);
            }}
          >
            <MenuItem value={"case"}>Nur dieser Fall</MenuItem>
            <MenuItem value={"account"}>Alle Daten und Fälle</MenuItem>
          </ValidatorSelect>
          {deleteType === "case" && (
            <DeleteCaseModalContent product={product} refreshPage={refreshPage} handleClose={handleClose} />
          )}
          {deleteType === "account" && (
            <DeleteAccountModalContent product={product} refreshPage={refreshPage} handleClose={handleClose} />
          )}
        </Grid>
      </Grid>
    </LegalbirdIoModal>
  );
}
