import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import { PaidTask } from "../../../types/PaidTask";
import ExternalPaidTaskTableRow from "./ExternalPaidTaskTableRow";
import { PAID_TASK_STATUS_APPROVED, PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_CREATED } from "../paidTaskStatus";
import { paidTaskTableStyle } from "./paidTaskTableStyle";
import moment from "moment/moment";
import Typography from "@mui/material/Typography";
import { TableComponents, TableVirtuoso } from "react-virtuoso";

type ExternalPaidTaskTableProps = {
  paidTasks: PaidTask[];
  isMinimalView?: boolean;
  isRequestedView?: boolean;
  noPaidTasksText?: string;
};

const getPaidTaskStyle = (paidTask: PaidTask) => {
  if (paidTask.status >= PAID_TASK_STATUS_APPROVED) {
    return paidTaskTableStyle.done;
  }

  if (paidTask.status === PAID_TASK_STATUS_CREATED && moment().isAfter(paidTask.assignmentDeadline, "day")) {
    return paidTaskTableStyle.overdue;
  }

  if (paidTask.status === PAID_TASK_STATUS_ASSIGNED && moment().isAfter(paidTask.doneDeadline, "day")) {
    return paidTaskTableStyle.overdue;
  }
};

const ExternalPaidTaskTable = ({
  paidTasks,
  isMinimalView = false,
  isRequestedView = false,
  noPaidTasksText = "Keine Aufgaben",
}: ExternalPaidTaskTableProps) => {
  function rowContent(_index: number, paidTask: PaidTask) {
    return (
      <ExternalPaidTaskTableRow
        key={_index}
        paidTask={paidTask}
        isMinimalView={isMinimalView}
        isRequestedView={isRequestedView}
      />
    );
  }

  function fixedHeaderContent() {
    return (
      <TableRow
        sx={{
          backgroundColor: "background.paper",
        }}
      >
        <TableCell />
        <TableCell>Fall</TableCell>
        <TableCell>Rechtsprodukt</TableCell>
        <TableCell>Aufgabe</TableCell>
        {!isMinimalView && <TableCell>Honorar</TableCell>}
        {isRequestedView && <TableCell>Annahme bis</TableCell>}
        <TableCell>Erledigung bis</TableCell>
        <TableCell />
      </TableRow>
    );
  }

  if (paidTasks.length === 0) {
    return (
      <Paper style={{ padding: 30 }}>
        <Typography textAlign={"center"}>{noPaidTasksText}</Typography>
      </Paper>
    );
  }

  const VirtuosoTableComponents: TableComponents<PaidTask> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => <Table {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />,
    TableHead,
    TableRow: ({ item: paidTask, ...props }) => <TableRow sx={getPaidTaskStyle(paidTask)} {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
  };

  return (
    <Paper style={{ height: "70vh" }}>
      <TableVirtuoso
        data={paidTasks}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Paper>
  );
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Fall</TableCell>
            <TableCell>Rechtsprodukt</TableCell>
            <TableCell>Aufgabe</TableCell>
            {!isMinimalView && <TableCell>Honorar</TableCell>}
            {isRequestedView && <TableCell>Annahme bis</TableCell>}
            <TableCell>Erledigung bis</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {paidTasks.length === 0 ? (
            <TableRow>
              <TableCell colSpan={10} align={"center"}>
                {noPaidTasksText}
              </TableCell>
            </TableRow>
          ) : (
            _.map(paidTasks, (paidTask) => (
              <ExternalPaidTaskTableRow
                key={paidTask.id}
                paidTask={paidTask}
                isMinimalView={isMinimalView}
                isRequestedView={isRequestedView}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ExternalPaidTaskTable;
