import { useRef, useState } from "react";
import { emailAttachmentStyle } from "./emailAttachmentStyle";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import _ from "lodash";
import { API_HOST, API_PATH } from "../../config/_entrypoint";
import { loadToken } from "../../services/localStorage";
import MediaObjectFormModal from "../Case/CaseUploadFile/MediaObjectFormModal";
import { AbstractCase } from "../../types/AbstractCase";
import { EmailAttachment as EmailAttachmentType } from "../../types/EmailAttachment";

type EmailAttachmentProps = {
  attachment: EmailAttachmentType;
  emailId: number;
  product: AbstractCase;
};

const EmailAttachment = ({ attachment, emailId, product }: EmailAttachmentProps) => {
  const myAnchor = useRef<SVGSVGElement | null>(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [addAttachmentModalOpen, setAddAttachmentModalOpen] = useState(false);

  return (
    <Grid container sx={emailAttachmentStyle.attachment} alignItems={"center"}>
      <Grid item xs={attachment.attachmentId ? 11 : 12}>
        {_.truncate(attachment.filename)}
      </Grid>
      {attachment.attachmentId && (
        <Grid item xs={1}>
          <IconButton sx={emailAttachmentStyle.iconButton} onClick={() => setMenuOpen(!menuOpen)} size="large">
            <MoreHoriz ref={myAnchor} />
          </IconButton>
          <Menu open={menuOpen} anchorEl={myAnchor.current} onClose={() => setMenuOpen(false)}>
            <MenuItem
              onClick={() => {
                setAddAttachmentModalOpen(true);
                setMenuOpen(false);
              }}
            >
              Datei zu Fall hinzufügen
            </MenuItem>
            <MenuItem
              component={"a"}
              download={true}
              href={
                API_HOST +
                API_PATH +
                "attachment/download?emailId=" +
                emailId +
                "&attachmentId=" +
                attachment.attachmentId +
                "&token=" +
                loadToken()
              }
            >
              Herunterladen
            </MenuItem>
          </Menu>
          <MediaObjectFormModal
            product={product}
            closeDialog={() => setAddAttachmentModalOpen(false)}
            open={addAttachmentModalOpen}
            fileData={{
              type: "emailAttachment",
              attachmentId: attachment.attachmentId,
              emailId: emailId,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default EmailAttachment;
