import ContentBox from "../ContentBox/ContentBox";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../services/Product/ProductService";
import React, { SyntheticEvent, useState } from "react";
import SearchBar from "../Algolia/SearchBar";
import { ALGOLIA_INDEX } from "../../config/_entrypoint";
import CaseHit from "../Case/CaseEntityPicker/Hit/CaseHit";
import { Hit } from "@algolia/client-search";
import { Delete } from "@mui/icons-material";
import useForm from "../../hooks/useForm";
import ValidatorTextField from "../Validator/ValidatorTextField";
import { convertToFloat, formValue } from "../../services/formServiceFunctions";
import { ibanFieldDefault, requiredFieldDefault } from "../../services/validationRules";
import SubmitButton from "../Button/SubmitButton";
import { apiPost } from "../../services/Api/apiCall";
import { AbstractCase } from "../../types/AbstractCase";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import ValidatorNumberField from "../Validator/ValidatorNumberField";

export default function AddWiretransfer({
  postSubmitCallback,
  currentCase,
}: {
  postSubmitCallback: Function;
  currentCase?: AbstractCase;
}) {
  const [caseHit, setCaseHit] = useState<Hit<any> | null>(null);
  const currentUser = useCurrentUser();

  const initialValues = {
    recipientName: "",
    amount: "",
    subject: "",
    iban: "",
  };

  const getCaseId = () => {
    if (!!currentCase) {
      return currentCase.id;
    }
    return caseHit?.productId || null;
  };

  const onSubmit = async ({ values }: any) => {
    let data = { ...values };
    data.amount = convertToFloat(data.amount);
    data.createdBy = currentUser["@id"];
    data.caseId = getCaseId();
    await apiPost("wiretransfers", data);
    setCaseHit(null);
    clearForm();
    postSubmitCallback();
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, handleBlur, clearForm, isLoading }: any =
    useForm({
      initialValues,
      onSubmit,
    });

  const onHitClick = async (hit: Hit<any>, event: SyntheticEvent<HTMLElement> | undefined) => {
    event && event.preventDefault();
    setCaseHit(hit);
  };

  return (
    <ContentBox headline="Neue Überweisung erstellen">
      {!currentCase && (
        <>
          <Typography variant={"h4"} sx={{ textAlign: "center", marginBottom: "1rem" }}>
            Fallzuordnung (optional)
          </Typography>
          <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"center"}>
            <Box>
              {!!caseHit?.productId ? (
                <>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={getCaseLink({ productClassName: caseHit.product, id: caseHit.productId })}
                    target={"_blank"}
                  >
                    {!!caseHit.label ? caseHit.label : "Zum Fall"}
                  </Link>
                  <IconButton onClick={() => setCaseHit(null)}>
                    <Delete />
                  </IconButton>
                </>
              ) : (
                "Unzugeordnet"
              )}
            </Box>
            <Box>
              <SearchBar
                searchConfig={{
                  indexName: ALGOLIA_INDEX,
                }}
                resultListConfig={{
                  itemSize: 330,
                  width: "100%",
                }}
                HitComponent={CaseHit}
                onHitClick={onHitClick}
              />
            </Box>
          </Stack>
          <Typography variant={"h4"} sx={{ textAlign: "center", margin: "1rem 0" }}>
            Überweisungsdaten
          </Typography>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ValidatorTextField
            label={"Empfänger"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"recipientName"}
            value={formValue(values, "recipientName")}
            validators={[...requiredFieldDefault]}
            registerValidators={registerValidators}
            error={!!errors["recipientName"]}
            helperText={errors["recipientName"]}
            isMandatory={false}
            dependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ValidatorTextField
            label={"IBAN"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"iban"}
            value={formValue(values, "iban")}
            validators={[...requiredFieldDefault, ...ibanFieldDefault]}
            registerValidators={registerValidators}
            error={!!errors["iban"]}
            helperText={errors["iban"]}
            isMandatory={false}
            dependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ValidatorNumberField
            fieldType={"money"}
            numberType={"float"}
            label={"Betrag"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"amount"}
            value={formValue(values, "amount")}
            registerValidators={registerValidators}
            error={!!errors["amount"]}
            helperText={errors["amount"]}
            isMandatory={true}
            dependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField
            label={"Verwendungszweck"}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"subject"}
            value={formValue(values, "subject")}
            validators={[...requiredFieldDefault]}
            registerValidators={registerValidators}
            error={!!errors["subject"]}
            helperText={errors["subject"] || "Maximal 140 Zeichen"}
            inputProps={{ maxLength: 140 }}
            isMandatory={false}
            dependentValidationFields={[]}
          />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton onClick={handleSubmit} variant={"contained"} isLoading={isLoading}>
            Speichern
          </SubmitButton>
        </Grid>
      </Grid>
    </ContentBox>
  );
}
