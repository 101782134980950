import React, { useMemo, useState } from "react";
import useStyles from "./processCostSupportStyle";
import Badge from "../Badges/Badge";
import { Link, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { getCaseLabel } from "../../services/Product/ProductService";
import PageContentContainer from "../Container/PageContentContainer";
import Paper from "@mui/material/Paper";
import LegalExpensesInsurance from "./Form/LegalExpensesInsurance";
import {
  convertToFloat,
  floatFields,
  getErrors,
  numberFields,
  prepareFormData,
} from "../../services/formServiceFunctions";
import _ from "lodash";
import Petitioner from "./Form/Petitioner";
import Job from "./Form/Job";
import LegalRepresentative from "./Form/LegalRepresentative";
import AlimonyClaims from "./Form/AlimonyClaims";
import Alimony from "./Form/Alimony";
import SocialWelfare from "./Form/SocialWelfare";
import IncomeEmployment from "./Form/IncomeEmployment";
import IncomeSelfEmployment from "./Form/IncomeSelfEmployment";
import IncomeRent from "./Form/IncomeRent";
import IncomeAssets from "./Form/IncomeAssets";
import IncomeChildAllowance from "./Form/IncomeChildAllowance";
import IncomeLiving from "./Form/IncomeLiving";
import IncomeAlimony from "./Form/IncomeAlimony";
import IncomePension from "./Form/IncomePension";
import IncomeUnemploymentAllowance from "./Form/IncomeUnemployementAllowance";
import IncomeUnemploymentAllowance2 from "./Form/IncomeUnemployementAllowance2";
import IncomeSickness from "./Form/IncomeSickness";
import IncomeParentalAllowance from "./Form/IncomeParentalAllowance";
import IncomeOther from "./Form/IncomeOther";
import IncomeDescriptive from "./Form/IncomeDescriptive";
import BankAccounts from "./Form/BankAccounts";
import RealEstate from "./Form/RealEstate";
import Vehicles from "./Form/Vehicles";
import CashValuables from "./Form/CashValuables";
import PensionInsurances from "./Form/PensionInsurances";
import OtherAssets from "./Form/OtherAssets";
import TaxDeduction from "./Form/TaxDeduction";
import SocialSecurityDeduction from "./Form/SocialSecurityDeduction";
import OtherInsuranceDeduction from "./Form/OtherInsuranceDeduction";
import MobilityCostDeduction from "./Form/MobilityCostDeduction";
import OtherExpensesDeduction from "./Form/OtherExpensesDeduction";
import LivingType from "./Form/LivingType";
import OtherPaymentObligations from "./Form/OtherPaymentObligations";
import SpecialBurdens from "./Form/SpecialBurdens";
import ScrollTopButton from "../Button/ScrollTopButton";
import ProcessCostSupportReceipts from "./ProcessCostSupportReceipts";
import {
  APPLICATION_STATUS_RECEIPTS,
  LEAD_STATUS_CHECKED,
  LEAD_STATUS_CHECKING,
  LEAD_STATUS_INITIAL,
} from "../../services/LeadStatus/StatusProcessCostSupport";
import ProcessCostSupportMissingDocuments from "./ProcessCostSupportMissingDocuments";
import NavigationExtensionProcessCostSupport from "../NavigationBar/NavigationExtensionProcessCostSupport";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../services/ReactQuery/reactQueryService";

const ProcessCostSupport = () => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);
  const updateMutation = useMutation(updateResource);

  const { id: idFromParams } = useParams();
  const { data: processCostSupport } = useQuery(queryKeys.item("process_cost_supports", idFromParams), () =>
    fetchResource("process_cost_supports", idFromParams)
  );

  const processCostSupportFormValues = useMemo(() => {
    if (!processCostSupport) {
      return;
    }
    return prepareFormData(processCostSupport);
  }, [processCostSupport]);

  if (!processCostSupport) {
    return null;
  }

  const getBadgeText = () => {
    switch (processCostSupport.leadStatus) {
      case LEAD_STATUS_INITIAL:
        if (processCostSupport.applicationStatus < APPLICATION_STATUS_RECEIPTS) {
          return "Daten unvollständig";
        }
        return "Belege unvollständig";
      case LEAD_STATUS_CHECKING:
        return "Zu prüfen";
      case LEAD_STATUS_CHECKED:
        return "Abgeschlossen";
      default:
        return "";
    }
  };

  const onSubmit = async ({ values, e }) => {
    if (!_.isEmpty(getErrors(e))) {
      return;
    }

    setIsLoading(true);
    let updateData = {};
    _.forEach(values, (value, key) => {
      _.set(updateData, key, value);
    });
    await saveProcessCostSupport(updateData, processCostSupport, updateMutation);
    setIsLoading(false);
  };

  const formProps = {
    pcs: processCostSupportFormValues,
    onSubmit: onSubmit,
    classes: classes,
    isLoading: isLoading,
  };

  return (
    <>
      <PageContentContainer size={"small"}>
        <Grid container justifyContent={"center"} className={classes.naviExtension} component={Paper}>
          <Grid item xs={12}>
            <NavigationExtensionProcessCostSupport processCostSupport={processCostSupport} />
          </Grid>
        </Grid>
        <Grid container className={classes.heading} justifyContent={"center"} alignItems={"center"} spacing={2}>
          <Grid item xs={3}>
            <Button component={Link} to={"/services/tools/verfahrenskostenhilfe"} fullWidth={false}>
              Zurück zur Übersicht
            </Button>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.smallHeadline}>Antrag auf Verfahrenskostenhilfe von</div>
            <div className={classes.headlineName}>
              <Typography variant={"h2"} sx={{ mt: 1, mb: 1 }}>
                {getCaseLabel({
                  ...processCostSupport,
                  productClassName: "processCostSupport",
                })}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={2}>
            <Badge text={getBadgeText()} />
          </Grid>
        </Grid>
        <div className={classes.scrollToAnchor} id={"personalData"} />
        <Paper className={classes.paper}>
          <div className={classes.paperHeadline}>Persönliche Angaben</div>
          <LegalExpensesInsurance {...formProps} />
          <Petitioner {...formProps} />
          <Job {...formProps} />
          <LegalRepresentative {...formProps} />
          <AlimonyClaims {...formProps} />
          <Alimony {...formProps} />
          <SocialWelfare {...formProps} />
        </Paper>
        <Paper className={classes.paper}>
          <div className={classes.paperHeadline}>Einnahmen</div>
          <IncomeEmployment {...formProps} />
          <IncomeSelfEmployment {...formProps} />
          <IncomeRent {...formProps} />
          <IncomeAssets {...formProps} />
          <IncomeChildAllowance {...formProps} />
          <IncomeLiving {...formProps} />
          <IncomeAlimony {...formProps} />
          <IncomePension {...formProps} />
          <IncomeUnemploymentAllowance {...formProps} />
          <IncomeUnemploymentAllowance2 {...formProps} />
          <IncomeSickness {...formProps} />
          <IncomeParentalAllowance {...formProps} />
          <IncomeOther {...formProps} />
          <IncomeDescriptive {...formProps} />
        </Paper>
        <Paper className={classes.paper}>
          <div className={classes.paperHeadline}>Vermögen</div>
          <BankAccounts {...formProps} />
          <RealEstate {...formProps} />
          <Vehicles {...formProps} />
          <CashValuables {...formProps} />
          <PensionInsurances {...formProps} />
          <OtherAssets {...formProps} />
        </Paper>
        <Paper className={classes.paper}>
          <div className={classes.paperHeadline}>Ausgaben</div>
          <TaxDeduction {...formProps} />
          <SocialSecurityDeduction {...formProps} />
          <OtherInsuranceDeduction {...formProps} />
          <MobilityCostDeduction {...formProps} />
          <OtherExpensesDeduction {...formProps} />
          <LivingType {...formProps} />
          <OtherPaymentObligations {...formProps} />
          <SpecialBurdens {...formProps} />
        </Paper>
        <div className={classes.scrollToAnchor} id={"receipts"} />
        <ProcessCostSupportReceipts processCostSupport={processCostSupport} />
        <ProcessCostSupportMissingDocuments processCostSupport={processCostSupport} />
        <Button component={Link} to={"/services/tools/verfahrenskostenhilfe"} fullWidth={false}>
          Zurück zur Übersicht
        </Button>
        <ScrollTopButton />
      </PageContentContainer>
    </>
  );
};

export default ProcessCostSupport;

const saveProcessCostSupport = async (updateData, processCostSupport, updateMutation) => {
  if (_.has(updateData, "petitioner")) {
    const petitionerData = updateData.petitioner;
    await updateMutation.mutateAsync({
      uri: "people",
      id: processCostSupport.petitioner.id,
      data: petitionerData,
    });
  }

  if (_.has(updateData, "petitioner.residenceAddress")) {
    const addressData = updateData.petitioner.residenceAddress;
    await updateMutation.mutateAsync({
      uri: "addresses",
      id: processCostSupport.petitioner.residenceAddress.id,
      data: addressData,
    });
  }

  if (_.has(updateData, "legalRepresentative")) {
    const legalRepresentativeData = updateData.legalRepresentative;
    await updateMutation.mutateAsync({
      uri: "people",
      id: processCostSupport.legalRepresentative.id,
      data: legalRepresentativeData,
    });
  }

  if (_.has(updateData, "legalRepresentative.residenceAddress")) {
    const addressData = updateData.legalRepresentative.residenceAddress;
    await updateMutation.mutateAsync({
      uri: "addresses",
      id: processCostSupport.legalRepresentative.residenceAddress.id,
      data: addressData,
    });
  }

  if (_.has(updateData, "alimonyObligatedPerson")) {
    const petitionerData = updateData.alimonyObligatedPerson;
    await updateMutation.mutateAsync({
      uri: "people",
      id: processCostSupport.alimonyObligatedPerson.id,
      data: petitionerData,
    });
  }

  const processCostSupportUpdate = handleProcessCostSupportFields(updateData);
  await updateMutation.mutateAsync({
    uri: "process_cost_supports",
    id: processCostSupport.id,
    data: processCostSupportUpdate,
  });
};

const handleProcessCostSupportFields = (data) => {
  let resultData = _.merge({}, data);

  _.forEach(resultData, (value, key) => {
    if (value === "yes" || value === "no") {
      resultData[key] = value === "yes";
    }
    if (floatFields.includes(key)) {
      resultData[key] = convertToFloat(value);
    }
    if (numberFields.includes(key)) {
      resultData[key] = Number.parseInt(value);
    }
  });

  return resultData;
};
