import React, { useState } from "react";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ButtonLoading from "../Button/ButtonLoading";
import BackofficeUserDataFormElements from "./BackofficeUserDataFormElements";
import { UseForm } from "../../types/UseForm";
import useForm from "../../hooks/useForm";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import { useSnackbar } from "notistack";
import Snackbar from "../Snackbar/Snackbar";

type NewBackofficeUserModalProps = {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  fetchBackofficeUsers: Function;
};

export default function NewBackofficeUserModal({
  isOpen,
  setIsOpen,
  fetchBackofficeUsers,
}: NewBackofficeUserModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    gender: "",
    givenName: "",
    familyName: "",
    email: "",
    roles: [],
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const { values, clearForm } = useFormProps;

    const usersForEmail = await ApiClient.get("backoffice_users?email=" + values.email);

    if (usersForEmail["hydra:totalItems"] > 0) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={"Der Nutzer konnte nicht angelegt werden, da diese E-Mail bereits vergeben ist."}
              isNonInteractive={true}
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      setIsLoading(false);
      return;
    }
    try {
      await ApiClient.post("backoffice_users", {
        body: JSON.stringify({
          email: values.email,
          roles: values.roles,
          personData: {
            gender: values.gender,
            givenName: values.givenName,
            familyName: values.familyName,
          },
        }),
      });
    } catch (e: any) {
      alert("Es ist ein Fehler aufgetreten");
      setIsLoading(false);
      return;
    }

    await fetchBackofficeUsers();
    clearForm();
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar message={"Nutzer wurde erfolgreich angelegt"} isNonInteractive={true} />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsLoading(false);
    setIsOpen(false);
  };

  const useFormProps: UseForm = useForm({
    initialValues,
    onSubmit,
  });

  return (
    <LegalbirdIoModal
      title={"Neuen Nutzer anlegen"}
      submitButton={
        <ButtonLoading variant={"contained"} onClick={useFormProps.handleSubmit} isLoading={isLoading}>
          Nutzer anlegen
        </ButtonLoading>
      }
      open={isOpen}
      handleClose={() => setIsOpen(false)}
    >
      <BackofficeUserDataFormElements
        useFormProps={useFormProps}
        initialValues={initialValues}
        roleEditingEnabled={true}
      />
    </LegalbirdIoModal>
  );
}
