import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { Checkbox, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import ApiClient from "../../../services/ApiClient";
import ButtonLoading from "../../Button/ButtonLoading";
import { getIdentifiersAutocompleteOptions } from "../../../services/identifierService";
import moment from "moment";
import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import useForm from "../../../hooks/useForm";
import { formValue } from "../../../services/formServiceFunctions";
import { requiredFieldDefault } from "../../../services/validationRules";
import ValidatorTextField from "../../Validator/ValidatorTextField";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import LegalbirdAutoComplete from "../../AutoComplete/LegalbirdAutoComplete";
import _ from "lodash";
import { useSnackbar } from "notistack";
import Snackbar from "../../Snackbar/Snackbar";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../services/ReactQuery/reactQueryService";

const MediaObjectFormModal = ({
  open,
  closeDialog,
  product,
  fileData,
  submitDisabled,
  children = null,
  requestUri = "media_objects",
  contentType = "multipart/form-data",
  errorMessage = "Datei konnte nicht hinzugefügt werden",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [identifierOptions, setIdentifierOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!open || !_.isEmpty(identifierOptions)) {
      return;
    }
    getIdentifiersAutocompleteOptions(product.productClassName, true).then((result) => {
      setIdentifierOptions(result);
    });
  }, [open, identifierOptions]);

  const getRequestData = () => {
    let payload = {};

    _.forEach(fileData, (value, key) => {
      payload[key] = value;
    });

    payload["product"] = product.productClassName;
    payload["productId"] = product.id;

    if (formValue(values, "noIdentifier") === false) {
      payload["identifier"] = formValue(values, "identifier.value");
    }

    if (showExtraFields) {
      payload["showInGallery"] = formValue(values, "showInGallery", "false");
      payload["showInExternalView"] = formValue(values, "showInGallery", "false");
      payload["description"] = formValue(values, "description");
      payload["electronicFileFolderPath"] = formValue(values, "electronicFileFolderPath");
    }

    if (contentType === "multipart/form-data") {
      let formData = new FormData();
      _.forEach(payload, (value, key) => {
        formData.append(key, value);
      });
      return formData;
    }
    return JSON.stringify(payload);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let headers = new Headers();
    headers.set("Content-Type", contentType);
    try {
      await ApiClient.post(requestUri, {
        headers: headers,
        body: getRequestData(),
      });
    } catch (e) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar message={errorMessage} isNonInteractive />
          </div>
        ),
        persist: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }

    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));
    setIsLoading(false);
    resetDialog();
  };

  const initialValues = {
    description: "Dokument vom " + moment().format("DD.MM.YYYY"),
    showInGallery: "blank",
    identifier: { label: "Ohne Label", value: "no_label" },
    electronicFileFolderPath: "blank",
    noIdentifier: false,
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, handleBlur, clearForm } = useForm({
    initialValues,
    onSubmit,
    clearFormAfterSubmit: true,
  });

  if (identifierOptions.length < 1) {
    return null;
  }

  const resetDialog = () => {
    clearForm();
    closeDialog();
  };

  const handleAutoCompleteValueChange = (e, value) => {
    handleChange({
      target: {
        name: "identifier",
        value: value,
      },
    });
  };

  const autoCompleteValue = _.find(
    identifierOptions,
    (identifier) => identifier.value === formValue(values, "identifier.value", "no_label")
  );

  const isNoIdentifier = formValue(values, "noIdentifier");
  const showExtraFields = autoCompleteValue.value === "no_label" || isNoIdentifier;

  return (
    <LegalbirdIoModal
      handleClose={resetDialog}
      open={open}
      disableBackdropClick
      title={"Datei Hochladen"}
      submitButton={
        <ButtonLoading
          variant={"contained"}
          onClick={handleSubmit}
          disabled={
            submitDisabled ||
            (showExtraFields && formValue(values, "electronicFileFolderPath") === "blank") ||
            (showExtraFields && formValue(values, "showInGallery") === "blank")
          }
          isLoading={isLoading}
        >
          Datei hinzufügen
        </ButtonLoading>
      }
    >
      {children}
      <Grid container spacing={2}>
        {autoCompleteValue.value === "no_label" && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: "primary.main" }}
                  checked={formValue(values, "noIdentifier")}
                  name={"noIdentifier"}
                  onChange={handleChange}
                />
              }
              label={"Keine Folge-Aktionen (E-Mail/Aktivität) notwendig"}
            />
          </Grid>
        )}
        {!isNoIdentifier && (
          <Grid item xs={5}>
            <LegalbirdAutoComplete
              setValue={handleAutoCompleteValueChange}
              value={autoCompleteValue}
              options={identifierOptions}
              label={"Art des Dokuments"}
            />
          </Grid>
        )}
        {showExtraFields && (
          <>
            <Grid item xs={isNoIdentifier ? 12 : 7}>
              <ValidatorTextField
                label={"Beschreibungstext"}
                name={"description"}
                value={formValue(values, "description")}
                onChange={handleChange}
                onBlur={handleBlur}
                registerValidators={registerValidators}
                validators={requiredFieldDefault}
                error={!!errors["description"]}
                helperText={errors["description"]}
              />
            </Grid>
            <Grid item xs={7}>
              <ValidatorSelect
                label={isNoIdentifier ? "Anzeige für Mandantschaft" : "Anzeige & Info an Mandantschaft"}
                value={formValue(values, "showInGallery")}
                onChange={handleChange}
                name={"showInGallery"}
              >
                <MenuItem value={"blank"} disabled>
                  {" "}
                </MenuItem>
                <MenuItem value={"true"}>Ja</MenuItem>
                <MenuItem value={"false"}>Nein</MenuItem>
              </ValidatorSelect>
            </Grid>
            <Grid item xs={5}>
              <ValidatorSelect
                label={"Ablage in Ordner"}
                name={"electronicFileFolderPath"}
                value={formValue(values, "electronicFileFolderPath")}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value={"blank"} disabled>
                  {" "}
                </MenuItem>
                <MenuItem value={"Mandant"}>Mandant</MenuItem>
                <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
                <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
                <MenuItem value={"RSV"}>RSV</MenuItem>
                <MenuItem value={"ToDo"}>{"ToDo"}</MenuItem>
                <MenuItem value={"Rechnungen"}>Rechnungen</MenuItem>
                <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
              </ValidatorSelect>
            </Grid>
          </>
        )}
      </Grid>
    </LegalbirdIoModal>
  );
};

MediaObjectFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  submitDisabled: PropTypes.bool,
  fileData: PropTypes.object.isRequired,
};

export default MediaObjectFormModal;
