import React from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import { activitiesFilterString } from "../../../hooks/useActivities";
import useActivitiesFetching from "../../../hooks/useActivitiesFetching";
import { useBackofficeUser } from "../../../provider/BackofficeUserProvider";
import _ from "lodash";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { Stack } from "@mui/material";
import ActivityNote from "./ActivityNote";

type ActivitiesProps = {
  product: AbstractCase;
};

export default function Activities({ product }: ActivitiesProps) {
  const { backofficeUsers } = useBackofficeUser();
  const filter = { deleted: false, pagination: false, type: "call_notes", done: false };
  const dataSource = activitiesFilterString(product, filter);
  const { activities, isLoading }: { activities: any; isLoading: boolean } = useActivitiesFetching(
    dataSource,
    backofficeUsers
  );

  if (isLoading) {
    return <LegalbirdLoader centered />;
  }

  return (
    <Stack spacing={3}>
      {_.map(activities, (activity) => (
        <ActivityNote key={activity.id} activity={activity} />
      ))}
    </Stack>
  );
}
