import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { STAGE_CHECKED, STAGE_DIVORCE_DONE } from "../../../services/Stages/StagesDivorce";
import { apiGet } from "../../../services/Api/apiCall";
import { AbstractCase } from "../../../types/AbstractCase";
import CostCalculatorDivorceResponse from "../../../types/CostCalculatorDivorceResponse";

const CostBoxDivorce = ({ divorce }: { divorce: AbstractCase }) => {
  const [costCalculatorResponse, setCostCalculatorResponse] = useState<CostCalculatorDivorceResponse | null>(null);

  useEffect(() => {
    apiGet("lb/get_fees/" + divorce.id).then((response: CostCalculatorDivorceResponse) => {
      setCostCalculatorResponse(response);
    });
  }, []);

  if (!costCalculatorResponse) {
    return null;
  }

  const costDifference = costCalculatorResponse.sumOfAllFees - costCalculatorResponse.sumOfAllFeesEstimate;
  const costDifferenceFormatted = Math.abs(costDifference).toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <Paper sx={{ marginY: "2rem", padding: "1rem" }}>
      <Typography variant={"h5"} textAlign={"center"}>
        Kosten
      </Typography>
      <p>
        Verfahrensgebühr inkl. Auslagenpauschale:{" "}
        {costCalculatorResponse.processFeeAndExpenses.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        Gerichtsgebühr:{" "}
        {costCalculatorResponse.courtFee.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        Terminsgebühr:{" "}
        {costCalculatorResponse.hearingFee.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
        <br />
        <strong>
          Scheidungskosten Gesamt:{" "}
          {(
            costCalculatorResponse.processFeeAndExpenses +
            costCalculatorResponse.courtFee +
            costCalculatorResponse.hearingFee
          ).toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
        </strong>
      </p>
      <p>
        Streitwert Scheidung:{" "}
        {costCalculatorResponse.valueInDisputeDivorce.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        Streitwert VA:{" "}
        {costCalculatorResponse.valueInDisputePensions.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
        <br />
        <strong>
          Streitwert Gesamt:{" "}
          {costCalculatorResponse.valueInDispute.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
        </strong>
      </p>
      {((!!divorce.stage && divorce.stage < STAGE_CHECKED) || divorce.installment) && (
        <p>
          <strong>Ratenzahlung</strong>
          <br />
          Anwaltskosten gesamt:{" "}
          {(costCalculatorResponse.installment * costCalculatorResponse.numberOfInstallments).toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
          <br />
          10 Raten zu je:{" "}
          {costCalculatorResponse.installment.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
          })}
        </p>
      )}
      {!!divorce.stage &&
        divorce.stage >= STAGE_DIVORCE_DONE &&
        costCalculatorResponse.processFee &&
        costCalculatorResponse.courtFee &&
        costCalculatorResponse.hearingFee && (
          <Box marginTop={"3rem"}>
            <Typography marginBottom={"1rem"} variant={"h4"} textAlign={"center"}>
              Tatsächliche Kosten
            </Typography>

            <p>
              Finaler Streitwert:{" "}
              {costCalculatorResponse.valueInDispute.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
              <br />
              Verfahrensgebühr inkl. Auslagenpauschale:{" "}
              {costCalculatorResponse.processFeeAndExpenses.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
              <br />
              Gerichtsgebühr:{" "}
              {costCalculatorResponse.courtFee.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
              <br />
              Terminsgebühr:{" "}
              {costCalculatorResponse.hearingFee.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
            </p>
            {costDifference !== 0 && !divorce.hasProcessCostSupport && (
              <Typography
                textAlign={"center"}
                padding={"1rem"}
                fontWeight={"bold"}
                sx={{ border: "1px solid black", borderRadius: 5 }}
              >
                {costDifference > 0
                  ? `Kunde muss ${costDifferenceFormatted} nachzahlen`
                  : `Kunde erhält Gutschrift über ${costDifferenceFormatted}`}
              </Typography>
            )}
          </Box>
        )}
    </Paper>
  );
};

export default CostBoxDivorce;
