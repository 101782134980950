/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import {Grid, InputAdornment} from "@mui/material";
import {floatFieldDefault, numberFieldDefault, requiredFieldDefault} from "../../services/validationRules";
import React, {useEffect} from "react";
import ValidatorTextField from "./ValidatorTextField";
import {ValidatorType} from "../../types/ValidatorType";
import {ThousandsNumberInput} from "../FormFields/MaskedInputs/ThousandsNumberInput";
import {ThousandsNumberInputFloat} from "../FormFields/MaskedInputs/ThousandsNumberInputFloat";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {ThousandsNumberInputFloatScaleThree} from "../FormFields/MaskedInputs/ThousandsNumberInputFloatScaleThree";

type ValidatorNumberFieldProps = {
  name: string;
  dependentValidationFields?: Array<string>;
  value: string | number;
  label: string;
  registerValidators?: Function;
  isMandatory?: boolean;
  validators?: Array<ValidatorType>;
  numberType?: "float" | "int";
  labelDependsOnField?: string | null;
  questionDependsOnField?: string | null;
  fieldType?: "money" | "percentage" | "kilometers" | null;
  floatScaleThree?: boolean;
  inputProps?: any;
} & TextFieldProps;
export default function ValidatorNumberField({
  name,
  value,
  label,
  registerValidators = () => {},
  validators = [],
  isMandatory = false,
  dependentValidationFields = [],
  numberType = "int",
  labelDependsOnField = null,
  questionDependsOnField = null,
  fieldType = null,
  inputProps = null,
  floatScaleThree = false,
  ...rest
}: ValidatorNumberFieldProps) {
  validators = [...validators];
  useEffect(() => {
    if (isMandatory) {
      validators.push(...requiredFieldDefault);
    }
    if (numberType === "int") {
      validators.push(...numberFieldDefault);
    }
    if (numberType === "float") {
      validators.push(...floatFieldDefault);
    }
  }, []);

  const endAdornments = {
    money: "€",
    percentage: "%",
    kilometers: "km",
  };

  function getInputComponent() {
    if (numberType === "int") {
      return ThousandsNumberInput;
    }
    return floatScaleThree ? ThousandsNumberInputFloatScaleThree : ThousandsNumberInputFloat;
  }

  return (
    <>
      <Grid item xs={12}>
        <ValidatorTextField
          name={name}
          label={label}
          validators={validators}
          InputProps={{
            inputComponent: getInputComponent(),
            endAdornment: !!fieldType ? (
              <InputAdornment position={"end"}>{endAdornments[fieldType]}</InputAdornment>
            ) : null,
            ...inputProps,
          }}
          value={value}
          registerValidators={registerValidators}
          isMandatory={isMandatory}
          dependentValidationFields={dependentValidationFields}
          {...rest}
        />
      </Grid>
    </>
  );
}
