import { SyntheticEvent, useState } from "react";
import SearchBar from "../../../Algolia/SearchBar";
import { ALGOLIA_INDEX } from "../../../../config/_entrypoint";
import CaseHit from "../../../Case/CaseEntityPicker/Hit/CaseHit";
import { Hit } from "@algolia/client-search";
import { Box, Typography } from "@mui/material";
import ContentBox from "../../../ContentBox/ContentBox";
import ButtonLoading from "../../../Button/ButtonLoading";
import ApiClient from "../../../../services/ApiClient";

export default function CalendlyClear() {
  const [isLoading, setIsLoading] = useState(false);
  const [caseHit, setCaseHit] = useState<Hit<any> | null>(null);

  const onHitClick = (caseHit: Hit<any>, event: SyntheticEvent<HTMLElement> | undefined) => {
    event?.preventDefault();
    setCaseHit(caseHit);
  };

  const clearCalendlyFields = async () => {
    if (!caseHit) {
      return;
    }
    setIsLoading(true);
    await ApiClient.post("lb/services/clear_calendly_fields", {
      body: JSON.stringify({
        product: caseHit.product,
        caseId: caseHit.productId,
      }),
    });
    setCaseHit(null);
    setIsLoading(false);
  };

  return (
    <ContentBox headline="Calendly Termin bereinigen">
      <Typography>
        Sollte ein fehlerhafter Calendlytermin (Termin konnte nicht erledigt werden, Fehler bei Löschung etc.) in der
        Servicewelt eines Mandanten angezeigt werden, so kann dieser hier bereinigt werden.
      </Typography>
      <Box margin={"1rem 0"}>
        <SearchBar
          searchConfig={{
            indexName: ALGOLIA_INDEX,
          }}
          resultListConfig={{
            itemSize: 330,
            width: "100%",
          }}
          HitComponent={CaseHit}
          onHitClick={onHitClick}
        />
      </Box>
      {!!caseHit && (
        <ButtonLoading variant={"contained"} onClick={clearCalendlyFields} isLoading={isLoading}>
          Termine für {caseHit.label} bereinigen
        </ButtonLoading>
      )}
    </ContentBox>
  );
}
