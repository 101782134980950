import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DatasetConfig } from "./datasetTypes";
import ApiClient from "../../services/ApiClient";
import _ from "lodash";
import useForm from "../../hooks/useForm";
import EntityFields from "./EntityFields";
import ButtonLoading from "../Button/ButtonLoading";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import SearchBar from "../Algolia/SearchBar";
import { duplicationChecker, DuplicationCheckResult } from "./datasetHelper";

export default function EditDataset({ datasetConfig }: { datasetConfig: DatasetConfig }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<any>(null);
  const [duplicationCheckResult, setDuplicationCheckResult] = useState<DuplicationCheckResult | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const pickEntity = (entityId: number) => {
    ApiClient.get(datasetConfig.entityApiData.entityApiUri + "/" + entityId).then((res) => setEntity(res));
  };

  const onSubmit = async ({ values }: any) => {
    setIsLoading(true);
    try {
      let updateData = _.merge({}, values, datasetConfig.entityApiData.additionalSaveData);
      if (datasetConfig.formFieldTransformations) {
        for (const [key, value] of Object.entries(datasetConfig.formFieldTransformations)) {
          if (_.has(updateData, key)) {
            _.set(updateData, key, value(_.get(updateData, key)));
          }
        }
      }
      const addressUpdateData = _.pickBy(_.get(updateData, "address"), (value) => !_.isEmpty(value));
      await ApiClient.put("addresses/" + values.address.id, {
        body: JSON.stringify(addressUpdateData),
      });
      updateData.address = undefined;
      await ApiClient.put(values["@id"], { body: JSON.stringify(updateData) });
      setIsLoading(false);
      enqueueSnackbar("", {
        content: () => (
            <Box>
              <Snackbar message={"Datensatz wurde erfolgreich bearbeitet"} />
            </Box>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 10000,
      });
    } catch (e: any) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar message={e.errors?.organization || "Es ist ein Fehler aufgetreten"} isNonInteractive />
          </div>
        ),
        persist: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setEntity(null);
  }, [datasetConfig.entityApiData.entityApiUri]);

  const initialValues = useMemo(() => {
    return _.cloneDeep(entity);
  }, [entity]);

  const useFormProps = useForm({
    initialValues,
    onSubmit,
    identifier: entity?.id,
  });

  const handleDuplicateCheck = async ({ target }: { target: HTMLInputElement }) => {
    if (target.value === "") {
      setDuplicationCheckResult(null);
      return;
    }
    const currentFieldname = target.name.split(".").pop() || "";
    let entityData = { ...useFormProps.values };
    const duplicationCheckResult = await duplicationChecker({
      values: entityData,
      type: datasetConfig.entityApiData.baseEntityAttribute,
      currentFieldname: currentFieldname,
      currentId: entity.id,
    });
    setDuplicationCheckResult(duplicationCheckResult);
  };

  return (
    <>
      <Paper sx={{ padding: "2rem", marginTop: "2rem" }}>
        <SearchBar
          searchConfig={datasetConfig.searchConfig}
          HitComponent={datasetConfig.HitComponent}
          onHitClick={pickEntity}
          resultListConfig={datasetConfig.resultListConfig}
        />
      </Paper>
      <Paper sx={{ padding: "2rem", marginTop: "2rem" }}>
        <Grid container spacing={3}>
          <EntityFields
            key={datasetConfig.entityApiData.entityApiUri}
            formElements={datasetConfig.entityFormElements}
            useFormProps={useFormProps}
            product={{}}
            disabled={!entity}
            validationCallback={handleDuplicateCheck}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "2rem" }}>
          {duplicationCheckResult?.hasPartialMatch && !duplicationCheckResult?.hasFullMatch && (
            <Box
              sx={{
                color: "orange",
                padding: "0.5rem",
                border: "1px solid orange",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              Achtung: Es gibt einen ähnlichen Datensatz. Im Zweifel erst kurz per Suche prüfen, dass tatsächlich dieser
              neue Datensatz notwendig ist. Bitte nur speichern, wenn so keine Dopplung erzeugt wird.
            </Box>
          )}
          {duplicationCheckResult?.hasFullMatch && (
            <Box
              sx={{
                color: "red",
                padding: "0.5rem",
                border: "1px solid red",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              Achtung: Es gibt bereits einen identischen Datensatz.
            </Box>
          )}
          <ButtonLoading
            onClick={useFormProps.handleSubmit}
            variant={"contained"}
            isLoading={isLoading}
            disabled={!entity || _.isEmpty(useFormProps.touchedValues) || duplicationCheckResult?.hasFullMatch}
          >
            Änderungen abschicken
          </ButtonLoading>
        </Grid>
      </Paper>
    </>
  );
}
