import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Grid, Hidden, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { navigationExtensionEmotionStyle } from "./navigationExtensionAllDataStyle";
import { ContentContainer } from "../Container/PageContentContainer";

const NavigationExtensionCaseAccountingPage = ({ product }: any) => {
  const [topOffset, setTopOffset] = useState(69);

  if (!product) {
    return null;
  }

  return (
    <Hidden lgDown>
      <Paper
        square
        ref={(el) => el && setTopOffset(el.getBoundingClientRect().top)}
        sx={navigationExtensionEmotionStyle.tabs({ top: topOffset })}
      >
        <ContentContainer size={"large"}>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Stack justifyContent={"center"} sx={{ marginLeft: "2rem" }}>
                <strong>{product.backofficeCase.label}</strong>
                Unser Zeichen: {product.reference}
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Tabs scrollButtons="auto" value={false} centered>
                <Tab component={Link} to={"#account"} label={"Konto"} />
                <Tab component={Link} to={"#addWiretransfer"} label={"Neue Überweisung"} />
                <Tab component={Link} to={"#addInvoice"} label={"Rechnung erstellen"} />
              </Tabs>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography textAlign={"right"} marginRight={"2rem"}>
                {product.insurance?.insurancePolicyNumber && (
                  <>Versicherungsnr.: {product.insurance.insurancePolicyNumber}</>
                )}
                <br />
                {product.insurance?.caseReferenceNumber && <>Schadennr.: {product.insurance.caseReferenceNumber}</>}
              </Typography>
            </Grid>
          </Grid>
        </ContentContainer>
      </Paper>
    </Hidden>
  );
};

export default NavigationExtensionCaseAccountingPage;
