import ContentBox from "../../../ContentBox/ContentBox";
import { useState } from "react";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import ButtonLoading from "../../../Button/ButtonLoading";
import ApiClient from "../../../../services/ApiClient";
import Snackbar from "../../../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { useBackofficeUser } from "../../../../provider/BackofficeUserProvider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function BeaFetchInbox() {
  const [isLoading, setIsLoading] = useState(false);
  const [lawyerId, setLawyerId] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { lawyers } = useBackofficeUser();

  const fetchMessages = async () => {
    setIsLoading(true);
    const result = await ApiClient.get("/bea/fetch/" + lawyerId);
    enqueueSnackbar("", {
      content: () => (
        <div>
          <Snackbar
            message={
              result
                ? "beA-Nachrichten konnten nicht importiert werden. Bitte versuchen Sie es später noch einmal"
                : "beA-Nachrichten wurden erfolgreich importiert"
            }
            isNonInteractive
          />
        </div>
      ),
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsLoading(false);
  };

  return (
    <ContentBox headline="beA-Nachrichten abrufen">
      <Box sx={{ textAlign: "center" }}>
        <Typography>
          Mit dieser Funktion kann der Abruf der beA-Nachrichten aus den Postfächern ins Backoffice manuell jederzeit
          gestartet werden.
        </Typography>
        <FormControl fullWidth={true} margin={"normal"}>
          <InputLabel>Anwalt</InputLabel>
          <Select value={lawyerId} label={"Anwalt"} onChange={(event: SelectChangeEvent) => setLawyerId(event.target.value)}>
            {lawyers.map((lawyer) => (
              <MenuItem key={lawyer.id} value={lawyer.id}>{lawyer.person.fullname}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <ButtonLoading
          onClick={fetchMessages}
          isLoading={isLoading}
          variant={"contained"}
          sx={{ marginTop: "1rem" }}
        >
          beA-Nachrichten abrufen
        </ButtonLoading>
      </Box>
    </ContentBox>
  );
}
