import React, { useCallback, useEffect, useState } from "react";
import ApiClient from "../../../services/ApiClient";
import { makeStyles } from "@mui/styles";
import { paidTaskTableStyle } from "./paidTaskTableStyle";
import { PAID_TASK_STATUS_CREATED, PAID_TASK_STATUS_PAID } from "../paidTaskStatus";
import PaidTaskFilter from "./PaidTaskFilter";
import PaidTaskTable from "./PaidTaskTable";
import _ from "lodash";
import { paidTaskListFilters } from "../../../services/paidTaskService";
import RequestedPaidTasks from "./RequestedPaidTasks";
import PageHeadline from "../../PageHeadline/PageHeadline";
import { Box, Paper } from "@mui/material";
import ExternalPaidTaskTable from "./ExternalPaidTaskTable";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { PaidTask } from "../../../types/PaidTask";
import { BackofficeUser } from "../../../types/BackofficeUser";

const getApiFilterByDisplayType = (displayType: string, currentUser: BackofficeUser) => {
  switch (displayType) {
    case "assignableView":
      return "?exists[deletedDate]=false&status=" + PAID_TASK_STATUS_CREATED;
    case "assigneeView":
      return "?exists[deletedDate]=false&assignee.id=" + currentUser.id + "&status[lt]=" + PAID_TASK_STATUS_PAID;
    default:
    case "internalView":
      return "?exists[deletedDate]=false&status[lt]=" + PAID_TASK_STATUS_PAID;
  }
};

type PaidTaskContainerProps = {
  displayType: string;
  headline: string;
};
const PaidTaskContainer = ({ displayType, headline }: PaidTaskContainerProps) => {
  const currentUser = useCurrentUser();
  const [paidTasks, setPaidTasks] = useState<PaidTask[]>([]);
  const [paidTasksFiltered, setPaidTasksFiltered] = useState<PaidTask[]>([]);
  const [paidTaskFilter, setPaidTaskFilter] = useState(() => {
    switch (displayType) {
      case "assignableView":
        return "open";
      case "assigneeView":
        return "assigned";
      case "internalView":
      default:
        return "inReview";
    }
  });

  const refreshFunction = useCallback(() => {
    ApiClient.get("paid_tasks" + getApiFilterByDisplayType(displayType, currentUser)).then((paidTasks) =>
      setPaidTasks(paidTasks["hydra:member"])
    );
  }, [displayType, currentUser]);

  useEffect(() => {
    refreshFunction();
  }, [refreshFunction]);

  useEffect(() => {
    if (paidTasks === null) {
      return;
    }

    const currentFilter = _.get(paidTaskListFilters, displayType + "." + paidTaskFilter);

    if (!currentFilter) {
      setPaidTasksFiltered([]);
      return;
    }

    const filteredPaidTasks = _.filter(paidTasks, currentFilter.filter);
    const sortedPaidTasks = !!currentFilter.sorter
      ? _.sortBy(filteredPaidTasks, currentFilter.sorter)
      : filteredPaidTasks;
    setPaidTasksFiltered(sortedPaidTasks);
  }, [paidTasks, paidTaskFilter]);

  if (paidTasks === null || paidTasksFiltered === null) {
    return null;
  }

  return (
    <div>
      {_.includes(["assignableView", "assigneeView"], displayType) && <RequestedPaidTasks />}
      <Box sx={{ marginTop: "2.5rem" }}>
        <PageHeadline main={headline} />
      </Box>
      <PaidTaskFilter displayType={displayType} paidTaskFilter={paidTaskFilter} setPaidTaskFilter={setPaidTaskFilter} />
      <br />
      {displayType === "internalView" ? (
        <>
          <PaidTaskTable paidTasks={paidTasksFiltered} refreshFunction={refreshFunction} />
        </>
      ) : (
        <Paper>
          <ExternalPaidTaskTable paidTasks={paidTasksFiltered} />
        </Paper>
      )}
    </div>
  );
};
export default PaidTaskContainer;
