import { useState } from "react";
import ButtonLoading from "../Button/ButtonLoading";
import ValidatorSelect from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import { MediaObject } from "../../types/MediaObject";
import { SelectChangeEvent } from "@mui/material";

type ChangeMediaObjectFolderModalProps = {
  setSelectSubfolderOpen: Function;
  selectSubfolderOpen: boolean;
  mediaObject: MediaObject;
  updateMediaObjects: Function;
  title: string;
  buttonText: string;
};
export default function ChangeMediaObjectFolderModal({
  setSelectSubfolderOpen,
  selectSubfolderOpen,
  mediaObject,
  updateMediaObjects,
  title = "Datei in anderen Ordner verschieben",
  buttonText = "Datei ablegen",
}: ChangeMediaObjectFolderModalProps) {
  const [subFolder, setSubFolder] = useState(mediaObject.electronicFileFolderPath || "ToDo");
  const [subFolderLoading, setSubFolderLoading] = useState(false);

  const handleElectronicfileFolderSave = () => {
    setSubFolderLoading(true);
    ApiClient.put("media_objects/" + mediaObject.id, {
      body: JSON.stringify({
        electronicFileFolderPath: subFolder,
        showInElectronicFile: true,
      }),
    }).then(() => {
      updateMediaObjects();
      setSubFolderLoading(false);
      setSelectSubfolderOpen(false);
    });
  };

  return (
    <LegalbirdIoModal
      handleClose={() => setSelectSubfolderOpen(false)}
      open={selectSubfolderOpen}
      title={title}
      submitButton={
        <ButtonLoading variant={"contained"} onClick={handleElectronicfileFolderSave} isLoading={subFolderLoading}>
          {buttonText}
        </ButtonLoading>
      }
    >
      <ValidatorSelect
        label={"Ordner auswählen"}
        name={"subFolder"}
        value={subFolder}
        onChange={(event: SelectChangeEvent) => setSubFolder(event.target.value)}
      >
        <MenuItem value={"Mandant"}>Mandant</MenuItem>
        <MenuItem value={"Gerichtliches Verfahren"}>Gerichtliches Verfahren</MenuItem>
        <MenuItem value={"Außergerichtliches Verfahren"}>Außergerichtliches Verfahren</MenuItem>
        <MenuItem value={"RSV"}>RSV</MenuItem>
        <MenuItem value={"ToDo"}>{"ToDo"}</MenuItem>
        <MenuItem value={"Rechnungen"}>Rechnungen</MenuItem>
        <MenuItem value={"Sonstiges"}>Sonstiges</MenuItem>
      </ValidatorSelect>
    </LegalbirdIoModal>
  );
}
