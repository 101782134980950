import React from "react";
import { IMaskInput } from "react-imask";
import IMask from "imask";

type IbanInputProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};
export const IbanInput = React.forwardRef(function IbanInput(props: IbanInputProps, ref: React.Ref<IMask.MaskElement>) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="aa00 0000 0000 0000 0000 00"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value: String(value) } })}
    />
  );
});
