import personTranslations from "./personTranslations";

const insuranceTranslations = {
  insurancePolicyNumber: {
    label: "Versicherungsnummer",
  },
  caseReferenceNumber: {
    label: "Schadennummer",
  },
  deductible: {
    label: "Selbstbehalt",
  },
  extrajudicialCoverageRequestAnsweredDate: {
    label: "Antwort RSV außergerichtlich",
  },
  extrajudicialCoverageGranted: {
    label: "Deckung außergerichtlich",
  },
  judicialCoverageRequestAnsweredDate: {
    label: "Antwort RSV gerichtlich",
  },
  judicialCoverageGranted: {
    label: "Deckung gerichtlich",
  },
  policyHolderType: {
    label: "Selbst oder mitversichert",
    values: {
      me: "Selbst versichert",
      familyMember: "Mitversichert",
    },
  },
  policyHolder: personTranslations,
  insurance: {
    label: "Versicherungsgesellschaft",
  },
};

export default insuranceTranslations;
