import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Snackbar from "../../Snackbar/Snackbar";
import { apiPost } from "../../../services/Api/apiCall";
import { getRequestSchufaDataCopyAllowed } from "../../../services/Product/RequestSchufaDataCopyModal/getRequestSchufaDataCopyAllowed";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/ReactQuery/reactQueryService";
import {useCurrentUser} from "../../../provider/CurrentUserProvider";

type RequestSchufaDataCopyModalProps = {
  product: AbstractCase;
  open: boolean;
  handleClose: Function;
};

export default function RequestSchufaDataCopyModal({ open, handleClose, product }: RequestSchufaDataCopyModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();


  let inquiryToCustomerFilter = {
    productId: product.id,
    "status[lt]": 40,
  };
  const { data: inquiryToCustomerCollection } = useQuery(
    queryKeys.collection("inquiry_to_customers", inquiryToCustomerFilter),
    () => fetchCollection("inquiry_to_customers", inquiryToCustomerFilter)
  );

  if (!inquiryToCustomerCollection) {
    return null;
  }

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await apiPost(`/lb/services/request_schufa_data_copy/${product.id}`, {
        createdBy: currentUser["@id"],
      });
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar isNonInteractive={true} message={"Schufa-Anfrage wurde automatisch an die Schufa versendet"} />
          </div>
        ),
      });
    } catch (e) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar isNonInteractive={true} message={"Schufa-Anfrage konnte nicht erstellt werden"} />
          </div>
        ),
      });
    }
    setIsLoading(false);
    handleClose();
  };
  const { allowed, message } = getRequestSchufaDataCopyAllowed(product, inquiryToCustomerCollection["hydra:member"]);

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"SCHUFA Datenauskunft anfordern"}
      actionsInfo={message ? <Typography sx={{ textAlign: "center", color: "red" }}>{message}</Typography> : null}
      submitButton={
        <ButtonLoading
          onClick={onSubmit}
          color="primary"
          variant={"contained"}
          isLoading={isLoading}
          disabled={!allowed}
        >
          Schufa-Anfrage stellen
        </ButtonLoading>
      }
    >
      <Typography align={"center"}>
        Möchten Sie eine SCHUFA-Datenauskunft für die Mandantschaft per E-Mail anfordern?
      </Typography>
    </LegalbirdIoModal>
  );
}
