const divorceTransformations = {
  dateFields: [
    { path: "dateMovedOut", type: "date" },
    { path: "dateSeparation", type: "date" },
    { path: "dateMarriage", type: "date" },
    { path: "powerOfAttorney", type: "dateTime" },
    { path: "installmentRateOverdueSince", type: "date" },
    { path: "courtFeeInvoiceReceivedDate", type: "date" },
    { path: "courtFeePaidDate", type: "date" },
    { path: "processCostSupportVerified", type: "date" },
    { path: "courtDate.appointmentDate", type: "date" },
    { path: "verification", type: "dateTime" },
  ],
  numberFields: [
    "leadStatus",
    "applicationStatus",
    "numberPensions",
    "numberPensionsPartner",
    "numberChildren",
    "netIncomePetitioner",
    "netIncomePartner",
    "responsibleCourt.advoAssistId",
  ],
  timeFields: ["courtDate.appointmentTime"],
  floatFields: ["valueInDisputeReal"],
};
export default divorceTransformations;
