import { isEmail, isNotDuplicateMail, isPhoneNumberWithoutSpecialCharacters } from "./validationRules";

export const isPhoneNumberWithoutSpecialCharactersDefault = {
  isPhoneNumberWithoutSpecialCharacters: (value: string) =>
    isPhoneNumberWithoutSpecialCharacters(value) ||
    "Die Telefonnummer darf keine Sonderzeichen enthalten oder mehrere führende Nullen haben.",
};
export const isEmailDefault = {
  isEmail: (value: string) => isEmail(value) || "Dies ist keine gültige E-Mail Adresse",
};
export const isDuplicateMailDefault = (originalMail: string) => ({
  duplicateMail: async (value: string) =>
    (await isNotDuplicateMail(value, originalMail)) || "Diese E-Mail Adresse existiert bereits",
});

export const requiredFieldDefault = {
  required: "Dies ist ein Pflichtfeld",
};

export const textFieldDefault = {
  minLength: {
    value: 2,
    message: "Der Text ist zu kurz",
  },
};
