import documentTransformations from "./documentTransformations";

const shortTimeWorkTransformations = {
  dateFields: [
    { path: "petitioner.birthDate", type: "date" },
    { path: "contractTerminationReceivedDate", type: "date" },
  ],
  numberFields: [...documentTransformations.numberFields, "percentageWorkTime", "weeklyWorkingHours", "grossSalary"],
  timeFields: [...documentTransformations.timeFields],
  floatFields: [...documentTransformations.floatFields],
};
export default shortTimeWorkTransformations;
