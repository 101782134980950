import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../../store/backofficeUser/actions";
import { checkToken, loadToken } from "../../../services/localStorage";
import { useSnackbar } from "notistack";
import Snackbar from "../../Snackbar/Snackbar";
import ApiClient from "../../../services/ApiClient";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";

export default function NewEmailPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isAuthenticated = checkToken(loadToken(), currentUser);
  const { enqueueSnackbar } = useSnackbar();

  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const token = query.get("t");

  useEffect(() => {
    if (isAuthenticated) {
      logout();
      return;
    }
    confirmEmail();
  }, [isAuthenticated]);

  const confirmEmail = useCallback(async () => {
    try {
      await ApiClient.post("/confirm_new_user_email", {
        body: JSON.stringify({
          id: id,
          token: token,
        }),
      });
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={
                "Sie haben Ihre neue E-Mail-Adresse erfolgreich bestätigt. Bitte melden Sie sich nun mit der neuen E-Mail-Adresse und Ihrem Passwort an."
              }
              isNonInteractive={true}
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 3000,
      });
    } catch (e) {
      enqueueSnackbar("", {
        content: () => (
          <div>
            <Snackbar
              message={
                "Wir konnten Ihre neue E-Mail-Adresse nicht setzen. Sollte dieses Problem bestehen bleiben wenden Sie sich bitte an unseren Kundenservice."
              }
              isNonInteractive={true}
            />
          </div>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 3000,
      });
    }
    navigate("/login");
  }, [id, token]);

  return null;
}
