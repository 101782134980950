import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./actionButtonStyle";
import { Grid, IconButton } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import MuiLink from "@mui/material/Link";
import { Accessible } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const ActionButton = ({
  icon: Icon = Accessible,
  text,
  to,
  href,
  onClick = () => {},
  isHidden = false,
  useCondensedView,
  divider = false,
  ...rest
}) => {
  const classes = useStyles();
  if (isHidden) {
    return null;
  }

  let iconButtonProps = {
    onClick: onClick,
  };
  if (to) {
    iconButtonProps.component = Link;
    iconButtonProps.to = to;
  }
  if (href) {
    iconButtonProps.component = MuiLink;
    iconButtonProps.href = href;
    iconButtonProps.target = "_blank";
    iconButtonProps.rel = "noreferrer";
    iconButtonProps.underline = "none";
  }

  const InternalTooltip = ({ children }) => {
    if (!useCondensedView) {
      return <>{children}</>;
    }
    return (
      <Tooltip title={text} placement={"left"}>
        {children}
      </Tooltip>
    );
  };

  return (
    <Grid container direction={"column"} alignItems={"center"} {...rest} className={"actionButton"}>
      <Grid item>
        <IconButton {...iconButtonProps} size="large">
          <InternalTooltip>
            <Icon fontSize={useCondensedView ? "medium" : "large"} />
          </InternalTooltip>
        </IconButton>
      </Grid>
      {!useCondensedView && (
        <Grid item className={classes.actionTitle}>
          {text}
        </Grid>
      )}
      {divider && <div className={classes.actionButtonDivider} />}
    </Grid>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.elementType,
  to: PropTypes.string,
  onClick: PropTypes.func,
  isHidden: PropTypes.bool,
  divider: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default ActionButton;
