import { AbstractCase } from "../../../types/AbstractCase";
import {InquiryToCustomer} from "../../../types/InquiryToCustomer";

export const getRequestSchufaDataCopyAllowed = (product: AbstractCase, inquiryToCustomers: Array<InquiryToCustomer>) => {
  if (!product.responsibleLawyer) {
    return {
      allowed: false,
      message: "Anfrage an Schufa kann nur gestellt werden, wenn ein Anwalt am Fall hinterlegt wurde.",
    };
  }
  if (inquiryToCustomers.length > 0) {
    return {
      allowed: false,
      message: "Es besteht bereits eine andere Anfrage an den Mandanten",
    };
  }
  return { allowed: true };
};
