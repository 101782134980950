import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import { paidTaskTableStyle } from "./paidTaskTableStyle";
import PaidTaskTableRow from "./PaidTaskTableRow";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import { PaidTask } from "../../../types/PaidTask";
import Typography from "@mui/material/Typography";
import { PAID_TASK_STATUS_APPROVED, PAID_TASK_STATUS_ASSIGNED, PAID_TASK_STATUS_CREATED } from "../paidTaskStatus";
import moment from "moment/moment";

type PaidTaskTableProps = {
  paidTasks: PaidTask[];
  refreshFunction: () => void;
};
const getPaidTaskStyle = (paidTask: PaidTask) => {
  if (paidTask.status >= PAID_TASK_STATUS_APPROVED) {
    return paidTaskTableStyle.done;
  }

  if (paidTask.status === PAID_TASK_STATUS_CREATED && moment().isAfter(paidTask.assignmentDeadline, "day")) {
    return paidTaskTableStyle.overdue;
  }

  if (paidTask.status === PAID_TASK_STATUS_ASSIGNED && moment().isAfter(paidTask.doneDeadline, "day")) {
    return paidTaskTableStyle.overdue;
  }
};
const PaidTaskTable = ({ paidTasks }: PaidTaskTableProps) => {
  const VirtuosoTableComponents: TableComponents<PaidTask> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => <Table {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />,
    TableHead,
    TableRow: ({ item: paidTask, ...props }) => <TableRow sx={getPaidTaskStyle(paidTask)} {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
  };

  function rowContent(_index: number, paidTask: PaidTask) {
    return <PaidTaskTableRow key={_index} paidTask={paidTask} />;
  }

  function fixedHeaderContent() {
    return (
      <TableRow
        sx={{
          backgroundColor: "background.paper",
        }}
      >
        <TableCell>Fall</TableCell>
        <TableCell>Rechtsprodukt</TableCell>
        <TableCell>Aufgabe</TableCell>
        <TableCell>Honorar</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Angefragt bei</TableCell>
        <TableCell>Angenommen von</TableCell>
        <TableCell>Annahme bis</TableCell>
        <TableCell>Erledigen bis</TableCell>
        <TableCell />
      </TableRow>
    );
  }

  if (paidTasks.length === 0) {
    return (
      <Paper style={{ padding: 30 }}>
        <Typography textAlign={"center"}>Keine Aufgaben</Typography>
      </Paper>
    );
  }

  return (
    <Paper style={{ height: "70vh" }}>
      <TableVirtuoso
        data={paidTasks}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Paper>
  );
};
export default PaidTaskTable;
