import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";
import ValidatorSelect from "../Validator/ValidatorSelect";
import MenuItem from "@mui/material/MenuItem";
import { translate } from "../../services/Translations/translatorService";

const CaseRejectionCategorySelect = ({ children, onChange, name, ...rest }) => {
  const handleRejectionCategoryChange = (event) => {
    let assessmentRejectionText = "";

    switch (event.target.value) {
      case "individual":
        assessmentRejectionText =
          "Das Ergebnis der Fallprüfung und die Einschätzung Ihres Falls haben wir bereits telefonisch besprochen.";
        break;
      case "electricityProvider":
        assessmentRejectionText =
          "Ihr Versorgungsunternehmen hat sich rechtlich korrekt verhalten, sodass ein Vorgehen " +
          "aus rechtlicher Sicht an der Stelle leider nicht möglich ist. Wir möchten Ihnen dies kurz erläutern:\n\n" +
          "Versorgungsunternehmen haben in der Regel - so auch in Ihrem Fall - die Möglichkeit, die vertraglich angebotenen " +
          "Preise anzupassen. Das geschieht vor allem immer dann, wenn sich die Energiepreise (und somit auch die " +
          "Bezugspreise für den Versorger für Strom und Gas) erhöhen. Es handelt sich also bei Ihren vertraglich vereinbarten " +
          "Preisen nicht um eine bindende Preisgarantie oder eine verbindliche Preisbindung, sondern um eine eingeschränkte" +
          " Preisbindung. Es gibt nur bei sehr wenigen Anbietern (vor allem bei sehr alten Verträgen) eine verbindliche " +
          "Preisgarantie.\n\n" +
          "Wichtig ist aus rechtlicher Sicht lediglich, dass Ihnen der Anbieter die Preisänderung transparent - zum Beispiel" +
          " per Post oder E-Mail - mitteilt und Ihnen ebenso ein Sonderkündigungsrecht einräumt. Dies hat Ihr Anbieter " +
          "mit seinem Schreiben ordnungsgemäß getan.\n\n" +
          "Welche Möglichkeiten haben Sie nun?\n" +
          "Wie oben beschrieben, können Sie den Anbieter juristisch nicht dazu zwingen, die ursprünglich vereinbarten " +
          "Preise aufrecht zu erhalten. Jedoch haben Sie die folgenden Möglichkeiten:\n\n" +
          "1. Sie akzeptieren die Preiserhöhung stillschweigend, d.h. Sie lassen den Vertrag weiterlaufen\n" +
          "2. Sie machen von Ihrem Sonderkündigungsrecht Gebrauch. Das bedeutet, dass der Vertrag mit Ihrem Versorger " +
          "endet und Sie infolgedessen wieder von Ihrem zuständigen Grundversorger bedient werden.\n\n" +
          "TIPP: Wie die aktuelle Preislage der Grundversorgung für Sie aussieht, können Sie bei Ihrem zuständigen " +
          "Grundversorger erfahren.\n\n" +
          "Wir können Ihre Verärgerung über die gestiegenen Preise gut nachvollziehen und bedauern, dass wir Ihnen hier " +
          "leider juristisch nicht weiter helfen können. Wir hoffen aber, dass wir dennoch ein wenig Licht in das " +
          "Thema bringen konnten und Ihnen so die Entscheidung über das weitere Vorgehen erleichtert haben.";
        break;
      case "opponentNonEu":
        assessmentRejectionText =
          "Der Firmensitz der Gegenseite befindet sich im Ausland (nicht EU). Es gelten hier entsprechend die " +
          "landesspezifischen Rechtsprechungen. Zudem sind Übersetzungen aller Schriftstücke notwendig. " +
          "Da wir lediglich auf Deutschland und das EU-Ausland spezialisiert sind, können wir Ihren Fall " +
          "leider nicht weiter bearbeiten.\n\n" +
          "In der Regel gibt es für derartige Fälle bei Ihrer Rechtsschutzversicherung spezialisierte Kanzleien. " +
          "Wenden Sie sich daher bitte für die weitere Klärung und Vermittlung eines Anwalts, der auf Fälle im Ausland " +
          "spezialisiert ist wieder an Ihre Versicherung.\n\n" +
          "Wir bedauern, Ihnen an dieser Stelle nicht direkt weiterhelfen zu können. Es ist uns jedoch wichtig, " +
          "dass Sie in Ihrem Fall bestmöglich vertreten werden.";
        break;
      case "fraudNoOpponent":
        assessmentRejectionText =
          "Es handelt sich hier sehr wahrscheinlich um einen Betrugsfall. Derartiges Vorgehen tritt leider aktuell vermehrt auf. Es werden hier in der Regel immer falsche Personen-, oder Firmen-Daten genutzt bzw. Daten von realen Personen missbraucht, sodass die Gegenseite meist eben nicht die genannte Person ist, sondern eine hintergründig agierende Person, die derartige Verbrechen massenhaft und professionell durchführt.\n\n" +
          "Ein Vorgehen ist ohne eine eindeutige Gegenseite samt zustellfähiger Postadresse leider an der Stelle durch uns nicht möglich. Der tatsächliche zivilrechtliche Anspruchsgegner muss zunächst polizeilich geklärt werden. Ihrerseits wäre daher nun der nächste Schritt Strafanzeige bei der nächstliegenden Polizeibehörde zu stellen.\n\n" +
          "Sollten Sie per Überweisung gezahlt haben, geben Sie beim Stellen der Strafanzeige unbedingt auch die Bankdaten an, die Sie für die Überweisung genutzt haben. Dies kann dabei helfen, die tatsächliche Gegenseite zu ermitteln.\n\n" +
          "Durch die Anzeige wird in dem strafrechtlichen Verfahren zunächst durch die Polizei geklärt, gegen wen sich Ihr Zahlungsanspruch richtet und zudem wenn möglich eine Postadresse ermittelt.\n\n" +
          "Bis dahin ist leider kein weiteres Vorgehen möglich. Sobald Sie Neuigkeiten zu dem strafrechtlichen Verfahren und insbesondere zu der Ermittlung der Adresse der Gegenseite (dem Anspruchsgegner) haben, setzen Sie sich bitte nochmals mit uns in Verbindung. Wir weisen Sie bereits jetzt rein vorsorglich darauf hin, dass das strafrechtliche Verfahren in derartigen Fällen oftmals nicht erfolgreich ist oder lediglich einen größeren “Verbrecherring” hervorbringt, sodass Sie Ihr Geld gegebenenfalls trotz erfolgreicher Ermittlungen nicht zurück erhalten werden.\n\n" +
          "Wir können Ihre Verärgerung sehr gut nachempfinden und bedauern, Ihnen an dieser Stelle zunächst keine andere Mitteilung geben zu können.";
        break;
      case "fraudNoOpponentAlreadyFiled":
        assessmentRejectionText =
          "Es handelt sich hier sehr wahrscheinlich um einen Betrugsfall. Derartiges Vorgehen tritt leider aktuell vermehrt auf. Es werden hier in der Regel immer falsche Personen-, oder Firmen-Daten genutzt bzw. Daten von realen Personen missbraucht, sodass die Gegenseite meist eben nicht die genannte Person ist, sondern eine hintergründig agierende Person, die derartige Verbrechen massenhaft und professionell durchführt.\n\n" +
          "Ein Vorgehen ist ohne eine eindeutige Gegenseite samt zustellfähiger Postadresse leider an der Stelle durch uns nicht möglich. Der tatsächliche zivilrechtliche Anspruchsgegner muss zunächst polizeilich geklärt werden.  Ihrerseits Strafanzeige bei der Polizeibehörde zu stellen war hier bereits der richtige Schritt.\n\n" +
          "Durch die Anzeige wird in dem strafrechtlichen Verfahren zunächst durch die Polizei geklärt werden müssen, gegen wen sich Ihr Zahlungsanspruch richtet und zudem wenn möglich eine Postadresse ermittelt.\n\n" +
          "Solange keine Gegenseite inklusive Postadresse bekannt ist, ist leider auch kein zivilrechtliches Vorgehen möglich. Wir weisen Sie bereits jetzt rein vorsorglich darauf hin, dass das strafrechtliche Verfahren in derartigen Fällen oftmals leider nicht erfolgreich ist, oder lediglich einen größeren “Verbrecherring” hervor bringt, sodass Sie Ihr Geld gegebenenfalls trotz erfolgreicher Ermittlungen nicht zurück erhalten werden.\n\n" +
          "Wir können Ihre Verärgerung sehr gut nachempfinden und bedauern, Ihnen an dieser Stelle keine andere Mitteilung geben zu können.";
        break;
      case "businessToBusinessContract":
        assessmentRejectionText =
          "In dem vorliegenden Fall haben Sie den Vertrag leider nicht als Privatperson abgeschlossen, sondern gewerblich. Somit ergibt sich leider eine völlig andere rechtliche Grundlage, als für einen privaten Verbraucher. Zum Beispiel gilt das 14-tägige Widerrufsrecht nur für private Verbraucher. Nur private Verbraucher können nach §355 BGB im Rahmen des Widerrufsrechtes von einem geschlossenen Vertrag zurücktreten. Sobald Sie jedoch einen Vertrag aus unternehmerischen Beweggründen geschlossen haben, gibt es ein solches Widerrufsrecht nicht. \n\n" +
          "Da wir lediglich auf Privatkunden spezialisiert sind, können wir Ihren Fall leider nicht weiter bearbeiten. In der Regel ist ein solcher gewerblicher Fall auch nicht von Ihrer privaten Rechtsschutzversicherung gedeckt. Wenden Sie sich daher bitte für die weitere Klärung und ggf. Vermittlung eines Anwalts, der auf Gewerberecht spezialisiert ist wieder an Ihre Versicherung. \n\n" +
          "Wir können Ihre Verärgerung sehr gut verstehen und bedauern, Ihnen an dieser Stelle keine andere Mitteilung geben zu können.";
        break;
      case "specialistLawyerNeeded":
        assessmentRejectionText =
          "In Ihrem Fall empfiehlt es sich, einen Fachanwalt für XXX zu beauftragen. " +
          "Wir bitten Sie daher, sich zur Vermittlung eines solchen Anwalts nochmals an Ihre Advocard " +
          "zu wenden. Wir bedauern, Ihnen an dieser Stelle nicht direkt weiterhelfen zu können. Es ist uns jedoch" +
          " wichtig, dass Sie in Ihrem Fall bestmöglich vertreten werden.";
        break;
      case "claimEnforcable":
        assessmentRejectionText =
          "Leider liegt in Ihrem Fall bereits eine vollstreckbare Forderung gegen Sie vor. Da wir " +
          "nicht im Bereich Zwangsvollstreckung tätig sind, können wir Ihr Mandat nicht übernehmen " +
          "und müssen Sie leider bitten, für anderweitige anwaltliche Vertretung zu sorgen. Wir " +
          "empfehlen Ihnen hierzu dringend, erneut Rücksprache mit Ihrer Rechtsschutzversicherung zu " +
          "halten und auch zu klären, ob diese die Kosten hierfür übernimmt.";
        break;
      case "enforceClaimTimeBarred":
        assessmentRejectionText =
          "Anhand Ihrer Fallschilderung und hochgeladenen Unterlagen haben wir festgestellt, " +
          "dass Ihre Forderung verjährt ist, sodass wir eine anwaltliche Tätigkeit nicht für erfolgsversprechend halten." +
          " Aufgrund der Verjährung haben Sie leider keine Möglichkeit, Ihre Forderungen gerichtlich durchzusetzen. " +
          "Wir bedauern, Ihnen an dieser Stelle keine andere Mitteilung geben zu können.";
        break;
      case "defendClaimTimeBarred":
        assessmentRejectionText =
          "Anhand Ihrer Fallschilderung und hochgeladenen Unterlagen haben wir festgestellt, " +
          "dass die Forderung der Gegenseite verjährt ist. Wir empfehlen Ihnen daher, in einem kurzen Schreiben an die" +
          " Gegenseite den Einwand der Verjährung zu erheben. Unserer Auffassung nach wird die Gegenseite den Anspruch" +
          " gegen Sie aufgrund der Verjährung nicht gerichtlich durchsetzen können, sodass die Angelegenheit damit " +
          "erledigt sein sollte.\r\n\r\nBenutzen Sie in Ihrem Schreiben zum Beispiel die folgende Formulierung: Die" +
          " erhobene Forderung ist mittlerweile verjährt. Deshalb erhebe ich hiermit die Einrede der Verjährung nach " +
          "§ 214 Abs. 1 BGB. Ich bitte um schriftliche Bestätigung, dass Sie die Forderung nicht weiter verfolgen und " +
          "keine weiteren Ansprüche hieraus mehr gegen mich ableiten.";
        break;
      case "unlikelySuccessful":
        assessmentRejectionText =
          "Leider ist in Ihrem Fall ein Vorgehen aus rechtlicher Sicht nicht erfolgsversprechend.\r\n\r\n" +
          "(Kurzer Text zur Begründung, warum Aussichten schlecht sind)\r\n\r\n" +
          "Wir können Ihre Verärgerung gut verstehen," +
          " sehen aber an dieser Stelle leider keine gute rechtliche Grundlage, um Ihre Position durchzusetzen." +
          " Aufgrund der schlechten Erfolgsaussichten ist es wahrscheinlich, dass durch Ihre Rechtsschutzversicherung " +
          "keine Kostenübernahme stattfinden wird.\r\n\r\n" +
          "Sollten Sie entgegen unserer Einschätzung doch ein " +
          "rechtliches Vorgehen wünschen, wenden Sie sich bitte wieder zurück an Ihre Versicherung, um dort zunächst eine " +
          "Kostendeckung in Ihrem Fall zu erfragen und sich dann dort gegebenenfalls einen anderen Anwalt vermitteln zu lassen.\r\n\r\n" +
          "Wir bedauern, Ihnen hier keine andere Nachricht überbringen zu können.";
        break;
      case "fraudLottery":
        assessmentRejectionText =
          "Es handelt sich hier um einen Betrugsfall. Das Forderungsschreiben, das sich auf Ansprüche der Euro Lotto Zentrale Euro Jackpot-6/49 bezieht, stammt von einer nicht existierenden Münchener Anwaltskanzlei. Auch die Euro Lotto Zentrale Euro Jackpot-6/49 existiert nicht. \n" +
          "\n" +
          "Von derartigen Schreiben sind aktuell viele Menschen betroffen. Wir raten Ihnen daher dringend, keine Forderung zu begleichen, da es andernfalls sehr schwierig werden wird, das Geld zurück zu erhalten.\n" +
          "\n" +
          "Sie können das Schreiben entsorgen, oder Strafantrag bei Ihrer örtlichen Polizeistation stellen, falls Sie eine Zahlung geleistet haben sollten. Die Staatsanwaltschaften ermitteln bereits in der Angelegenheit. \n" +
          "\n" +
          "Ein zivilrechtliches Vorgehen ist hier unserseits nicht möglich und auch nicht notwendig, da die Gegenseite in der Form nicht existiert.";
        break;
      case "schufaNoPositiveData":
        assessmentRejectionText =
          "Die Prüfung der SCHUFA-Auskunft hat ergeben, dass Ihr Mobilfunkanbieter keine Positivdaten nach dem 25.05.2018 " +
          "(Inkrafttreten der DSGVO) an die SCHUFA übermittelt hat. Es können alle Einträge nach dem Inkrafttreten der " +
          "DSGVO berücksichtigt werden. Daher gibt es in Ihrem Fall keine Grundlage für ein rechtliches Vorgehen und Sie haben leider keinen Anspruch auf Schadensersatz. ";
        break;
      default:
        break;
    }

    onChange(event);
    const splitName = _.split(name, ".");
    splitName.pop();
    const controlledFieldName = splitName.join(".") + ".assessmentRejectionText";
    onChange({ target: { name: controlledFieldName, value: assessmentRejectionText } });
  };

  return (
    <ValidatorSelect {...rest} name={name} onChange={handleRejectionCategoryChange}>
      <MenuItem value={"none"}>{translate(`${name}.values.none`)}</MenuItem>
      <MenuItem value={"individual"}>{translate(`${name}.values.individual`)}</MenuItem>
      <MenuItem value={"electricityProvider"}>{translate(`${name}.values.electricityProvider`)}</MenuItem>
      <MenuItem value={"opponentNonEu"}>{translate(`${name}.values.opponentNonEu`)}</MenuItem>
      <MenuItem value={"fraudLottery"}>{translate(`${name}.values.fraudLottery`)}</MenuItem>
      <MenuItem value={"fraudNoOpponent"}>{translate(`${name}.values.fraudNoOpponent`)}</MenuItem>
      <MenuItem value={"fraudNoOpponentAlreadyFiled"}>
        {translate(`${name}.values.fraudNoOpponentAlreadyFiled`)}
      </MenuItem>
      <MenuItem value={"businessToBusinessContract"}>{translate(`${name}.values.businessToBusinessContract`)}</MenuItem>
      <MenuItem value={"specialistLawyerNeeded"}>{translate(`${name}.values.specialistLawyerNeeded`)}</MenuItem>
      <MenuItem value={"claimEnforcable"}>{translate(`${name}.values.claimEnforcable`)}</MenuItem>
      <MenuItem value={"enforceClaimTimeBarred"}>{translate(`${name}.values.enforceClaimTimeBarred`)}</MenuItem>
      <MenuItem value={"defendClaimTimeBarred"}>{translate(`${name}.values.defendClaimTimeBarred`)}</MenuItem>
      <MenuItem value={"unlikelySuccessful"}>{translate(`${name}.values.unlikelySuccessful`)}</MenuItem>
      <MenuItem value={"schufaNoPositiveData"}>{translate(`${name}.values.schufaNoPositiveData`)}</MenuItem>
    </ValidatorSelect>
  );
};

CaseRejectionCategorySelect.propTypes = {
  validators: PropTypes.arrayOf(PropTypes.object),
  dependentValidationFields: PropTypes.array,
  registerValidators: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.any,
  isMandatory: PropTypes.bool,
};

CaseRejectionCategorySelect.defaultProps = {
  isMandatory: false,
  validators: [],
  dependentValidationFields: [],
};

export default CaseRejectionCategorySelect;
