import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { prepareFormCustomerDates } from "../../services/formServiceFunctions";
import _ from "lodash";
import { getProductData } from "../../services/Product/ProductService";
import { difference, persistCustomer, persistPerson } from "../../services/Persist/Persister";
import SubmitButton from "../Button/SubmitButton";
import { translate } from "../../services/Translations/translatorService";
import { useCase } from "../../provider/CaseProvider";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import SelectElement from "../ReactHookFormElements/SelectElement";
import { DevTool } from "@hookform/devtools";
import { Call } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {
    isDuplicateMailDefault,
    isEmailDefault,
    isPhoneNumberWithoutSpecialCharactersDefault,
    requiredFieldDefault,
    textFieldDefault,
} from "../../services/reactHookFormValidationRules";
import FormElementAlert from "../Case/CaseForm/FormElementAlert";

const Contact = () => {
    const [isShowAlert, setIsShowAlert] = useState(false);
    const { product, refreshCase } = useCase();
    const customer = product!.customer!;
    const petitioner = product!.petitioner;

    const timeSlots = [];
    for (let i = 8; i <= 18; i++) {
        timeSlots.push(
            <MenuItem key={i} value={"" + i}>
                {i} {translate("customer.contactTime.values.time")}
            </MenuItem>
        );
    }

    const initialValues: Record<string, any> = {
        customer: prepareFormCustomerDates(customer),
        petitioner: petitioner,
    };

    const onSubmit = async (data: Record<string, any>) => {
        const updatedCustomer = await persistCustomer(
            customer.id,
            difference(data.customer, prepareFormCustomerDates(customer))
        );
        await persistPerson(petitioner.id, difference(data.petitioner, petitioner));
        await refreshCase();
        formContext.reset((formValues) => ({ ...formValues, "customer.email": updatedCustomer.email }));
    };

    const formContext = useForm({
        mode: "onBlur",
        defaultValues: initialValues,
    });

    formContext.register("customer.email", {
        onBlur: () => setIsShowAlert(true),
    });

    return (
        <>
            <FormContainer formContext={formContext} onSuccess={onSubmit}>
                <TextFieldElement
                    name={"customer.telephone"}
                    label={translate("customer.telephone.label")}
                    validation={{ validate: isPhoneNumberWithoutSpecialCharactersDefault }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    color={"secondary"}
                                    href={"tel:" + formContext.watch("customer.telephone")}
                                    disabled={!formContext.watch("customer.telephone")}
                                    size="large"
                                >
                                    <Call />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextFieldElement
                    name={"customer.alternativeTelephone"}
                    label={translate("customer.alternativeTelephone.label")}
                    validation={{ validate: isPhoneNumberWithoutSpecialCharactersDefault }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    color={"secondary"}
                                    href={"tel:" + formContext.watch("customer.telephone")}
                                    disabled={!formContext.watch("customer.telephone")}
                                    size="large"
                                >
                                    <Call />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextFieldElement
                    name={"customer.email"}
                    label={translate("customer.email.label")}
                    onBlur={() => setIsShowAlert(true)}
                    validation={{
                        ...requiredFieldDefault,
                        validate: {
                            ...isEmailDefault,
                            ...isDuplicateMailDefault(customer.email),
                        },
                    }}
                />
                <FormElementAlert
                    alert={{
                        onChange:
                            "Das Ändern der E-Mail-Adresse führt dazu, dass der Kunde sich nicht mehr mit seiner alten Adresse in der Servicewelt anmelden kann, sondern nur noch mit der neuen Adresse.",
                    }}
                    initialValue={customer.email}
                    currentValue={formContext.watch("customer.email")}
                    path={"customer.email"}
                    setIsShowAlert={setIsShowAlert}
                    isShowAlert={isShowAlert}
                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        formContext.setValue("customer.email", event.target.value)
                    }
                    label={translate("customer.email.label")}
                />
                <TextFieldElement name={"customer.verification"} label={translate("customer.verification.label")} disabled />
                <SelectElement name={"customer.contactTime"} label={translate("customer.contactTime.label")}>
                    <MenuItem value={"flexible"}>{translate("customer.contactTime.values.flexible")}</MenuItem>
                    {timeSlots}
                </SelectElement>
                {product!.leadStatus < getProductData(product!.productClassName, "minimumCaseStatus") && (
                    <>
                        <SelectElement
                            name={"petitioner.gender"}
                            label={translate(`${product!.productClassName}.petitioner.gender.label`)}
                        >
                            <MenuItem value={`male`}>
                                {translate(`${product!.productClassName}.petitioner.gender.values.male`)}
                            </MenuItem>
                            <MenuItem value={`female`}>
                                {translate(`${product!.productClassName}.petitioner.gender.values.female`)}
                            </MenuItem>
                        </SelectElement>
                        <TextFieldElement
                            name={"petitioner.givenName"}
                            label={translate(`${product!.productClassName}.petitioner.givenName.label`)}
                            validation={{
                                ...textFieldDefault,
                                ...requiredFieldDefault,
                            }}
                        />
                        <TextFieldElement
                            name={"petitioner.familyName"}
                            label={translate(`${product!.productClassName}.petitioner.familyName.label`)}
                            validation={{
                                ...textFieldDefault,
                                ...requiredFieldDefault,
                            }}
                        />
                    </>
                )}
                <SubmitButton
                    sx={{ marginTop: 4 }}
                    error={_.keys(formContext.formState.errors).length > 0}
                    isLoading={formContext.formState.isSubmitting}
                    disabled={!formContext.formState.isDirty}
                    variant={"contained"}
                    type={"submit"}
                >
                    Speichern
                </SubmitButton>
            </FormContainer>
            <DevTool control={formContext.control} />
        </>
    );
};

export default Contact;
