import React, { useMemo } from "react";
import * as PropTypes from "prop-types";
import CourtHit from "./CourtHit";
import ApiClient from "../../services/ApiClient";
import { ALGOLIA_COURT_INDEX } from "../../config/_entrypoint";
import { useCase } from "../../provider/CaseProvider";
import _ from "lodash";
import SearchBar from "../Algolia/SearchBar";

const CourtPicker = ({ disabled, courtTypes }) => {
  const { product, refreshCase } = useCase();

  const parentEntityUrl = product["@id"];

  const onHitClick = async (courtId) => {
    await ApiClient.put(parentEntityUrl, {
      body: JSON.stringify({
        responsibleCourt: "/courts/" + courtId,
        backofficeCaseId: product.backofficeCase.id,
      }),
    });
    await refreshCase();
  };

  const algoliaSearchOptions = useMemo(() => {
    let filterString = "";

    _.forEach(courtTypes, (courtType, index) => {
      if (index === 0) {
        filterString += "type:" + courtType;
        return;
      }
      filterString += " OR type:" + courtType;
    });

    return {
      filters: filterString,
    };
  }, [courtTypes]);

  return (
    <SearchBar
      disabled={disabled}
      searchConfig={{
        indexName: ALGOLIA_COURT_INDEX,
        requestOptions: algoliaSearchOptions,
      }}
      resultListConfig={{
        itemSize: 200,
      }}
      HitComponent={CourtHit}
      onHitClick={onHitClick}
    />
  );
};

CourtPicker.propTypes = {
  disabled: PropTypes.bool,
  courtTypes: PropTypes.array.isRequired,
};

CourtPicker.defaultProps = {
  disabled: false,
};

export default CourtPicker;
