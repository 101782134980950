import React, { useState } from "react";
import moment from "moment";
import { Box } from "@mui/material";
import ButtonLoading from "../Button/ButtonLoading";
import LegalbirdIoModal from "../Modal/LegalbirdIoModal";
import ApiClient from "../../services/ApiClient";
import Snackbar from "../Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { useCurrentUser } from "../../provider/CurrentUserProvider";
import { AbstractCase } from "../../types/AbstractCase";
import { EmailMessage } from "../../types/EmailMessage";

type EmailTransmissionReportModalProps = {
  emailMessage: EmailMessage;
  product: AbstractCase;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function EmailTransmissionReportModal({
  emailMessage,
  product,
  isOpen,
  setIsOpen,
}: EmailTransmissionReportModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  const sender = emailMessage.from?.email;
  if (
    !sender ||
    (sender !== "service@legalbird.de" && sender !== "anwalt@legalbird-kanzlei.de") ||
    moment(emailMessage.created).add(30, "days").isBefore(moment(), "days")
  ) {
    return null;
  }

  const generateEmailTransmissionReport = async () => {
    setIsLoading(true);
    const data = {
      productClassName: product.productClassName,
      productId: product.id,
      mailSubject: emailMessage.messageContent.subject,
      senderEmailAddress: sender,
      recipientEmailAddress: emailMessage.to?.email,
      createdBy: currentUser["@id"],
    };
    try {
      await ApiClient.post("lb/services/backoffice_mail_transmission_report", { body: JSON.stringify(data) });
    } catch (e) {
      enqueueSnackbar("", {
        content: () => (
          <Box>
            <Snackbar message="Mail-Sendebericht konnte nicht abgerufen werden" isNonInteractive={true} />
          </Box>
        ),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
    setIsOpen(false);
  };

  return (
    <LegalbirdIoModal
      handleClose={() => setIsOpen(false)}
      open={isOpen}
      disableBackdropClick
      title="Sendebericht anfordern"
      submitButton={
        <ButtonLoading onClick={() => generateEmailTransmissionReport()} variant="contained" isLoading={isLoading}>
          Sendebericht anfordern
        </ButtonLoading>
      }
    >
      <p>
        Mit dem Klick auf "Sendebericht anfordern", wird der Sendebericht für diese E-Mail im Dokumentenbereich
        angehangen.
      </p>
    </LegalbirdIoModal>
  );
}
