import * as stagesSettlement from "../../Stages/StagesSettlement";
import {
  beaSafeIdFieldDefault,
  dateFieldDefault,
  emailDefault,
  faxNumberDefault,
  ibanFieldDefault,
  insurancePickerValidator,
  numberFieldDefault,
  postalCodeDefault,
  textFieldDefault,
} from "../../validationRules";
import { formValue } from "../../formServiceFunctions";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import { trueFalseDefaultOptions } from "./formDefinitionFunctions";
import { getCourtAppointmentFields } from "./contract/fullFormDefinition";
import _ from "lodash";
import legalForms from "../../legalForms";
import { allDataPageCourtFields } from "./allDataPageCourtFields";
import { hasActiveCourtAppointment } from "../ProductService";
import { IbanInput } from "../../../components/MaskedInputs/IbanInput";
import personFields from "./modules/personFields";
import addressFields from "./modules/addressFields";

const lateFinancialInfo = [
  {
    type: "ValidatorDateField",
    path: "settlement.settlementRevocationDate",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorNumberField",
    path: "settlement.valueInDispute",
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorNumberField",
    path: "settlement.nWnFAmount",
    isHidden: ({ product }) => product.paymentType !== "nwnf",
    fieldType: "money",
    numberType: "float",
  },
];

const employer = {
  label: "Gegenseite - Arbeitgeber",
  elements: [
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.name",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.representedBy",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: textFieldDefault,
      additionalProps: {
        helperText: "Bei mehreren Personen bitte komma-separiert eintragen - z.B.: Max Mustermann, Liselotte Lustig",
      },
    },
    {
      type: "ValidatorSelect",
      path: "settlement.employerOrganization.legalForm",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      options: () => [
        { value: "__null__", label: "Keine Gesellschaftsform" },
        ..._.map(legalForms, (legalForm) => {
          return {
            value: legalForm.label,
            label: legalForm.label,
          };
        }),
      ],
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.address.streetAddress",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.address.postalCode",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: [...textFieldDefault, postalCodeDefault],
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.address.addressLocality",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.address.postOfficeBoxNumber",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.address.postOfficeBoxPostalCode",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: [...textFieldDefault, postalCodeDefault],
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.address.postOfficeBoxAddressLocality",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.email",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.telephone",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: textFieldDefault,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.employerOrganization.fax",
      isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
      validators: faxNumberDefault,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
  ],
};

const opponentLawyer = {
  label: "Anwalt Gegenseite",
  elements: [
    {
      type: "BeaLawyerImportButton",
      path: "pathPropNotUsedButRequired",
      additionalProps: {
        fullWidth: true,
      },
    },
    ...personFields({ path: `settlement.opponentLawyer` }),
    {
      type: "ValidatorTextField",
      path: `settlement.opponentLawFirm.name`,
    },
    {
      type: "ValidatorTextField",
      path: `settlement.opponentLawFirm.email`,
      validators: emailDefault,
    },
    {
      type: "ValidatorTextField",
      path: `settlement.opponentLawFirm.telephone`,
    },
    {
      type: "ValidatorTextField",
      path: `settlement.opponentLawFirm.fax`,
      validators: faxNumberDefault,
      additionalProps: {
        InputProps: {
          startAdornment: <InputAdornment position="start">+49</InputAdornment>,
        },
      },
    },
    {
      type: "ValidatorTextField",
      path: `settlement.opponentLawFirm.beaSafeId`,
      validators: beaSafeIdFieldDefault,
    },
    ...addressFields({ path: `settlement.opponentLawFirm.address` }),
    {
      type: "ValidatorTextField",
      path: `settlement.referenceOpponentLawyer`,
    },
  ],
};

const settlementCourtFields = [
  {
    type: "ValidatorDateField",
    path: "settlement.settlementReachedDate",
    validators: dateFieldDefault,
  },
];

const preChecked = [
  {
    type: "ValidatorDateField",
    path: "settlement.dismissalProtectionSuitDeadline",
    validators: dateFieldDefault,
  },
  {
    type: "ValidatorTextField",
    path: "settlement.powerOfAttorney",
    isDisabled: () => true,
  },
  {
    type: "ValidatorSelect",
    path: "settlement.paymentType",
    options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "nwnf" }, { value: "private" }],
  },
  {
    type: "ValidatorNumberField",
    path: "settlement.nWnFAmount",
    isHidden: ({ values }) => formValue(values, "settlement.paymentType") !== "nwnf",
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorSelect",
    path: "settlement.contractTerminated",
    options: () => trueFalseDefaultOptions("settlement.contractTerminated"),
  },
  {
    type: "ValidatorSelect",
    path: "settlement.numberOfEmployees",
    options: () => [{ value: "__null__" }, { value: 10 }, { value: 50 }, { value: 500 }, { value: 5000 }],
  },
  {
    type: "ValidatorDateField",
    path: "settlement.employedSince",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorNumberField",
    path: "settlement.grossSalary",
    fieldType: "money",
    numberType: "float",
  },
  {
    type: "ValidatorTextField",
    path: "settlement.jobDescription",
    validators: [...textFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.worksCouncil",
    options: () => trueFalseDefaultOptions("settlement.worksCouncil"),
  },
  {
    type: "ValidatorSelect",
    path: "settlement.terminationObstacles",
    options: ({ values, product }) => {
      let obstacles = [];
      obstacles.push({ value: "none" });
      obstacles.push({ value: "parent" });
      product.petitioner.gender === "female" && obstacles.push({ value: "pregnant" });
      obstacles.push({ value: "disabled" });
      formValue(values, "settlement.worksCouncil") === true && obstacles.push({ value: "council" });
      obstacles.push({ value: "privacyOfficer" });
      obstacles.push({ value: "apprentice" });
      return obstacles;
    },
    additionalProps: {
      multiple: true,
    },
  },
  {
    type: "ValidatorDateField",
    path: "settlement.contractTerminationReceivedDate",
    validators: [...dateFieldDefault],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.terminationNoticeType",
    options: () => [{ value: "__null__" }, { value: "withoutNotice" }, { value: "notice" }, { value: "unsure" }],
  },
  {
    type: "ValidatorSelect",
    path: "settlement.preferredOutcome",
    options: () => [{ value: "__null__" }, { value: "settlement" }, { value: "employment" }],
  },
];

export const stageFormDefinition = {
  [stagesSettlement.STAGE_INCOMPLETE]: preChecked,
  [stagesSettlement.STAGE_READY]: preChecked,
  [stagesSettlement.STAGE_CHECKED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.dismissalProtectionSuitDeadline",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.insuranceCoverageDate",
      isHidden: ({ product }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
  ],
  [stagesSettlement.STAGE_SUBMITTED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.dismissalProtectionSuitDeadline",
      isDisabled: () => true,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.dismissalProtectionSuitSubmittedDate",
      isDisabled: () => true,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.insuranceCoverageDate",
      isHidden: ({ product }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
    ...settlementCourtFields,
  ],
  [stagesSettlement.STAGE_PAID]: [
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.insuranceCoverageDate",
      isHidden: ({ product }) => product.paymentType !== "insurance",
      validators: dateFieldDefault,
    },
    ...settlementCourtFields,
  ],
  [stagesSettlement.STAGE_DATE_SET_SETTLEMENT]: [
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    ...settlementCourtFields,
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_DATE_SET_TRIAL]: [
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.settlementReachedDate",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.finalJudgement",
      validators: dateFieldDefault,
      isHidden: ({ values }) => !!formValue(values, "settlement.settlementReachedDate"),
    },
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_DECISION_MADE]: [
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.settlementReachedDate",
      validators: dateFieldDefault,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.finalJudgement",
      validators: dateFieldDefault,
      isHidden: ({ values }) => !!formValue(values, "settlement.settlementReachedDate"),
    },
    ...lateFinancialInfo,
  ],
  [stagesSettlement.STAGE_COMPLETED]: [
    {
      type: "ValidatorDateField",
      path: "settlement.settlementReachedDate",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }) => !product.settlementReachedDate,
    },
    {
      type: "ValidatorDateField",
      path: "settlement.finalJudgement",
      validators: dateFieldDefault,
      isDisabled: () => true,
      isHidden: ({ product }) => !product.finalJudgement,
    },
    {
      type: "ValidatorTextField",
      path: "settlement.referenceCourt",
      validators: textFieldDefault,
    },
  ],
};

export const fullFormDefinition = (product) => ({
  product: {
    sections: [
      {
        label: "Zur Person",
        elements: [
          {
            type: "ValidatorSelect",
            path: "settlement.petitioner.gender",
            options: () => [{ value: "male" }, { value: "female" }],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.givenName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.familyName",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorDateField",
            path: "settlement.petitioner.birthDate",
            validators: [...dateFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.residenceAddress.streetAddress",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.residenceAddress.postalCode",
            validators: [...textFieldDefault, postalCodeDefault],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.petitioner.residenceAddress.addressLocality",
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.maritalStatus",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [{ value: "unmarried" }, { value: "married" }, { value: "divorced" }],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.partnerIsWorking",
            options: () => trueFalseDefaultOptions("settlement.partnerIsWorking"),
            isDisabled: ({ values, product }) =>
              formValue(values, "settlement.maritalStatus") !== "married" ||
              product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.numberChildren",
            validators: [...numberFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorTextField",
            path: `${product.productClassName}.clientBankData.iban`,
            validators: ibanFieldDefault,
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            additionalProps: {
              InputProps: {
                inputComponent: IbanInput,
              },
            },
          },
        ],
      },
      {
        label: "Zur Kündigung",
        elements: [
          {
            type: "ValidatorSelect",
            path: "settlement.contractTerminated",
            options: () => trueFalseDefaultOptions("settlement.contractTerminated"),
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorSelect",
            path: "settlement.numberOfEmployees",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [{ value: 10 }, { value: 50 }, { value: 500 }, { value: 1000 }],
          },
          {
            type: "ValidatorDateField",
            path: "settlement.employedSince",
            validators: [...dateFieldDefault],
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
          },
          {
            type: "ValidatorNumberField",
            path: "settlement.grossSalary",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            fieldType: "money",
            numberType: "float",
          },
          {
            type: "ValidatorTextField",
            path: "settlement.jobDescription",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: [...textFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.worksCouncil",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => trueFalseDefaultOptions("settlement.worksCouncil"),
          },
          {
            type: "ValidatorSelect",
            path: "settlement.terminationObstacles",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: ({ values, product }) => {
              let obstacles = [];
              obstacles.push({ value: "none" });
              obstacles.push({ value: "parent" });
              product.petitioner.gender === "female" && obstacles.push({ value: "pregnant" });
              obstacles.push({ value: "disabled" });
              formValue(values, "settlement.worksCouncil") === true && obstacles.push({ value: "council" });
              obstacles.push({ value: "privacyOfficer" });
              obstacles.push({ value: "apprentice" });
              return obstacles;
            },
            additionalProps: {
              multiple: true,
            },
          },
          {
            type: "ValidatorDateField",
            path: "settlement.contractTerminationReceivedDate",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: [...dateFieldDefault],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.terminationNoticeType",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [
              { value: "__null__" },
              { value: "withoutNotice" },
              { value: "notice" },
              { value: "unsure" },
            ],
          },
          {
            type: "ValidatorSelect",
            path: "settlement.preferredOutcome",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [{ value: "__null__" }, { value: "settlement" }, { value: "employment" }],
          },
        ],
      },
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "settlement.personalTextMessage",
            isDisabled: () => true,
            additionalProps: {
              multiline: true,
              rows: 12,

              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Ergänzende Angaben für Klage",
        elements: [
          {
            type: "ValidatorDateField",
            path: "settlement.dismissalProtectionSuitDeadline",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.contractTerminationDate",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.lastWorkDayCancellation",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.contractDate",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.lastWorkDayCalculated",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            validators: dateFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "settlement.bargainingCoverage",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => trueFalseDefaultOptions("settlement.bargainingCoverage"),
          },
          {
            type: "ValidatorSelect",
            path: "settlement.terminationType",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [
              { value: "__null__" },
              {
                value: "betriebsbedingte Kündigung",
                label: "betriebsbedingte Kündigung",
              },
              {
                value: "personenbedingte Kündigung",
                label: "personenbedingte Kündigung",
              },
              {
                value: "verhaltensbedingte Kündigung",
                label: "verhaltensbedingte Kündigung",
              },
              { value: "krankheitsbedingte Kündigung", label: "krankheitsbedingte Kündigung" },
              {
                value: "Änderungskündigung",
                label: "Änderungskündigung",
              },
            ],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.protectionSuitText",
            validators: textFieldDefault,
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            additionalProps: {
              multiline: true,
              rows: 8,
              fullWidth: true,
            },
          },
        ],
      },
    ],
  },
  additional: {
    sections: [
      {
        label: "",
        elements: [
          {
            type: "ValidatorTextField",
            path: "settlement.reference",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.powerOfAttorney",
            isDisabled: () => true,
          },
          {
            type: "ValidatorDateField",
            path: "settlement.dismissalProtectionSuitSubmittedDate",
            isDisabled: () => true,
          },
          {
            type: "ValidatorTextField",
            path: "settlement.referenceCourt",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorSelect",
            path: "settlement.paymentType",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            options: () => [{ value: "__null__" }, { value: "insurance" }, { value: "nwnf" }, { value: "private" }],
          },
          {
            type: "ValidatorTextField",
            path: "settlement.valueInDispute",
            fieldType: "money",
            numberType: "float",
          },
          {
            type: "ValidatorNumberField",
            path: "settlement.nWnFAmount",
            isHidden: ({ values }) => formValue(values, "settlement.paymentType") !== "nwnf",
            isDisabled: ({ product }) =>
              [stagesSettlement.STAGE_COMPLETED, stagesSettlement.STAGE_DECISION_MADE].includes(product.stage),
            fieldType: "money",
            numberType: "float",
          },
        ],
      },
      {
        label: "Angaben zur Versicherung",
        isHidden: ({ values }) => formValue(values, "settlement.paymentType") !== "insurance",
        elements: [
          {
            type: "InsurancePicker",
            path: "settlement.insurance.insurance",
            validators: insurancePickerValidator(product),
          },
          {
            type: "ValidatorTextField",
            path: "settlement.insurance.insurancePolicyNumber",
            validators: textFieldDefault,
          },
          {
            type: "ValidatorNumberField",
            path: "settlement.insurance.deductible",
            isDisabled: ({ product }) => product.stage > stagesSettlement.STAGE_READY,
            fieldType: "money",
            numberType: "float",
          },
          {
            type: "ValidatorTextField",
            path: "settlement.insurance.caseReferenceNumber",
            validators: textFieldDefault,
            isHidden: ({ values }) => formValue(values, "settlement.paymentType") !== "insurance",
          },
        ],
      },
      {
        label: "Rechnungen & Zahlungen",
        isHidden: ({ product }) => product.paymentType === "nwnf",
        elements: [
          {
            type: "ValidatorDateField",
            path: "settlement.insuranceCoverageDate",
            validators: dateFieldDefault,
            isHidden: ({ product }) => product.paymentType !== "insurance",
            isDisabled: ({ product }) => product.stage === stagesSettlement.STAGE_COMPLETED,
          },
        ],
      },
      {
        label: "Gerichtsdaten",
        elements: [
          {
            type: "ValidatorDateField",
            path: "settlement.settlementReachedDate",
            validators: dateFieldDefault,
            isDisabled: ({ product }) =>
              ![
                stagesSettlement.STAGE_SUBMITTED,
                stagesSettlement.STAGE_PAID,
                stagesSettlement.STAGE_DATE_SET_SETTLEMENT,
                stagesSettlement.STAGE_DATE_SET_TRIAL,
              ].includes(product.stage),
          },
          {
            type: "ValidatorDateField",
            path: "settlement.finalJudgement",
            validators: dateFieldDefault,
            isHidden: ({ product }) => !!product.settlementReachedDate,
            isDisabled: ({ product }) =>
              ![stagesSettlement.STAGE_DECISION_MADE, stagesSettlement.STAGE_DATE_SET_TRIAL].includes(product.stage),
          },
          {
            type: "ValidatorDateField",
            path: "settlement.settlementRevocationDate",
            validators: dateFieldDefault,
            isDisabled: ({ product }) =>
              ![
                stagesSettlement.STAGE_DATE_SET_SETTLEMENT,
                stagesSettlement.STAGE_DATE_SET_TRIAL,
                stagesSettlement.STAGE_DECISION_MADE,
              ].includes(product.stage),
          },
        ],
      },
      {
        label: product.courtAppointments.length === 1 ? "Gerichtstermin" : "Gerichtstermine",
        isHidden: ({ product }) => product.courtAppointments.length === 0,
        elements: getCourtAppointmentFields(product),
      },
    ],
  },
  processParticipants: {
    sections: [employer, opponentLawyer],
  },
  additionalProcessParticipants: {
    sections: [
      {
        label: "Personen",
        elements: [
          {
            type: "ProcessParticipantsPeopleForm",
            path: `${product.productClassName}.relatedPeople`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
      {
        label: "Unternehmen",
        elements: [
          {
            type: "ProcessParticipantsOrganizationsForm",
            path: `${product.productClassName}.relatedOrganizations`,
            additionalProps: {
              fullWidth: true,
            },
          },
        ],
      },
    ],
    withoutSubmit: true,
  },
  court: {
    sections: [
      {
        elements: [
          {
            type: "CourtPicker",
            path: "settlement.responsibleCourt",
            isDisabled: ({ product }) =>
              product.stage >= stagesSettlement.STAGE_SUBMITTED || hasActiveCourtAppointment(product.courtAppointments),
            additionalProps: {
              fullWidth: true,
              courtTypes: ["Amtsgericht", "Arbeitsgericht", "Landgericht", "Mahngericht"],
            },
          },
          ...allDataPageCourtFields("settlement", "responsibleCourt"),
        ],
      },
    ],
  },
});
