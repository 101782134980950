import React from "react";
import DisplayMediaObject from "../MediaObjectSection/DisplayMediaObject";
import _ from "lodash";
import * as PropTypes from "prop-types";
import { MediaObject } from "../../types/MediaObject";

type DisplayMediaObjectsProps = {
  mediaObjects: MediaObject[];
  mediaObjectToDisplay: MediaObject | null;
  refreshMediaObjects?: () => void;
  setMediaObjectToDisplay: (mediaObject: MediaObject | null) => void;
  titleIsEditable?: boolean;
};

export default function DisplayMediaObjects({
  mediaObjects,
  refreshMediaObjects,
  titleIsEditable = false,
  mediaObjectToDisplay,
  setMediaObjectToDisplay,
}: DisplayMediaObjectsProps) {
  if (!mediaObjectToDisplay) {
    return null;
  }

  let currentIndex = _.findIndex(mediaObjects, (mediaObject) => mediaObject.id === mediaObjectToDisplay.id);

  if (currentIndex === -1) {
    return null;
  }

  const hasNextImage = typeof mediaObjects[currentIndex + 1] !== "undefined";
  const hasPreviousImage = typeof mediaObjects[currentIndex - 1] !== "undefined";

  const switchImage = (direction: string) => {
    if (direction === "next" && hasNextImage) {
      setMediaObjectToDisplay(mediaObjects[currentIndex + 1]);
    }
    if (direction === "previous" && hasPreviousImage) {
      setMediaObjectToDisplay(mediaObjects[currentIndex - 1]);
    }
  };

  return (
    <DisplayMediaObject
      closeDialog={() => setMediaObjectToDisplay(null)}
      mediaObject={mediaObjectToDisplay}
      hasNextImage={hasNextImage}
      hasPreviousImage={hasPreviousImage}
      refreshMediaObjects={refreshMediaObjects}
      setMediaObjectToPreview={setMediaObjectToDisplay}
      switchImage={switchImage}
      titleIsEditable={titleIsEditable}
    />
  );
}

DisplayMediaObjects.propTypes = {
  mediaObjects: PropTypes.array.isRequired,
  mediaObjectToDisplay: PropTypes.object,
  refreshMediaObjects: PropTypes.func,
  setMediaObjectToDisplay: PropTypes.func.isRequired,
  titleIsEditable: PropTypes.bool,
};
